import { ThumbsUp } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import { useParentEventsQuery } from "../../../api/blockchain/events";
import { CompanyInformation } from "../../../types/models/administration";
import { CompanyInvolvement } from "../../../types/models/company";
import { TParentEvent } from "../../../types/models/events";
import { Loading } from "../../design-system/Loading";

type DoneProps = {
  currentCompany: CompanyInformation | CompanyInvolvement;
  isReview: boolean;
  isRollback: boolean;
  isRejected: boolean;
  event?: TParentEvent;
};

const Done = ({
  currentCompany,
  isReview,
  isRollback,
  isRejected,
  event,
}: DoneProps) => {
  const i18n = useTranslation();
  const eventsQuery = useParentEventsQuery({
    orgNumber: currentCompany.orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  });

  if (
    eventsQuery.isFetching ||
    eventsQuery.isLoading ||
    eventsQuery.isRefetching
  ) {
    return <Loading />;
  }

  const events = eventsQuery.data?.data || [];
  const isApproved = isRollback
    ? event?.type === "LedgerRollbackPending" &&
      events[0]?.date === event.rollback_date
    : events[0]?.status === "Approved";
  const copyPrefix = isApproved
    ? isRollback
      ? "eventsWizard.done.rolledback"
      : "eventsWizard.done.approved"
    : !isReview
    ? "eventsWizard.done.publish"
    : isRollback
    ? isRejected
      ? "eventsWizard.done.rollback.rejected"
      : "eventsWizard.done.rollback.approved"
    : isRejected
    ? "eventsWizard.done.approval.rejected"
    : "eventsWizard.done.approval.approved";

  return (
    <div className="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-text-center tw-align-middle">
      <ThumbsUp className="tw-h-20 tw-w-20" />
      <h3 className="tw-font-medium">{i18n.t(`${copyPrefix}.title`)}</h3>
      <p>{i18n.t(`${copyPrefix}.description`)}</p>
    </div>
  );
};

export { Done };
