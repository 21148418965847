import { ArrowSquareOut, Info } from "@phosphor-icons/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import { ParentEventWithStatus } from "../../api/blockchain/events";
import { useDeleteSharedViewMutation } from "../../api/rest/views";
import { Badge } from "../../components/design-system/Badge";
import { Button } from "../../components/design-system/Button";
import { Description } from "../../components/design-system/Description";
import {
  PencilIcon,
  ThreeDotsIcon,
  TrashIcon,
} from "../../components/design-system/icons";
import { Menu } from "../../components/design-system/Menu";
import { TableV2 } from "../../components/design-system/TableV2";
import { TooltipV2 } from "../../components/design-system/Tooltip/TooltipV2";
import { nameToInitials } from "../../components/EventList/EventsTable/EventsTable.utils";
import { useCurrentCompany } from "../../context/account";
import { useSession } from "../../context/session";
import { getLocale } from "../../i18n";
import { Entity } from "../../types/models/entities";
import { SharedView } from "../../types/models/views";
import { formatEventLabel } from "../../utils/events-utils";
import { hasRequiredPermission } from "../../utils/permissions";

type ViewsTableProps = {
  views: SharedView[];
  entities: Entity[];
  events: ParentEventWithStatus[];
  sortBy: string;
  setSortBy: (value: string) => void;
  setEditView: (value: string) => void;
};

const emailsToDisplay = 3;

const ViewsTable = ({
  views,
  entities,
  events,
  sortBy,
  setSortBy,
  setEditView,
}: ViewsTableProps) => {
  const i18n = useTranslation();
  const currentCompany = useCurrentCompany();
  const { user } = useSession();
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const [expanded, setExpanded] = useState<Record<number, boolean>>({});
  const [expandedEmails, setExpandedEmails] = useState<Record<string, boolean>>(
    {}
  );
  const deleteMutation = useDeleteSharedViewMutation(currentCompany?.orgNumber);

  if (!currentCompany) {
    return null;
  }

  const now = new Date();
  const canEdit = hasRequiredPermission("Administrator", currentCompany, user);
  const sortedViews = views.sort((a, b) => {
    const [sortByColumn, sortOrder] = sortBy.split("-");
    if (sortByColumn === "created") {
      return sortOrder === "asc"
        ? a.createdAt > b.createdAt
          ? 1
          : -1
        : b.createdAt > a.createdAt
        ? 1
        : -1;
    }
    if (sortByColumn === "expiry") {
      return sortOrder === "asc"
        ? a.expiryDate > b.expiryDate
          ? 1
          : -1
        : b.expiryDate > a.expiryDate
        ? 1
        : -1;
    }
    if (sortByColumn === "createdBy") {
      const entityA = entities.find((e) => e.id === a.createdBy);
      const entityB = entities.find((e) => e.id === b.createdBy);
      return sortOrder === "asc"
        ? (entityA?.name || "").localeCompare(entityB?.name || "")
        : (entityB?.name || "").localeCompare(entityA?.name || "");
    }
    if (sortByColumn === "version") {
      return sortOrder === "asc"
        ? a.shareLedgerVersion.localeCompare(b.shareLedgerVersion)
        : b.shareLedgerVersion.localeCompare(a.shareLedgerVersion);
    }
    return 0;
  });

  return (
    <TableV2
      columns={[
        {
          name: "version",
          title: i18n.t("views.column.version"),
          key: true,
        },
        {
          name: "createdBy",
          title: i18n.t("views.column.createdBy"),
          key: !isTabletOrMobileDevice,
        },
        {
          name: "created",
          title: i18n.t("views.column.created"),
          key: !isTabletOrMobileDevice,
        },
        {
          name: "expiry",
          title: i18n.t("views.column.expiry"),
          key: !isTabletOrMobileDevice,
        },
        {
          name: "emails",
          title: i18n.t("views.column.emails"),
          key: !isTabletOrMobileDevice,
          sortable: false,
        },
        {
          name: "actions",
          title: "",
          key: !isTabletOrMobileDevice,
          sortable: false,
        },
      ]}
      data={sortedViews.map((view) => {
        const event = events.find((e) => e.date === view.shareLedgerVersion);
        const createdBy = entities.find((e) => e.id === view.createdBy);
        const displayEmails = expandedEmails[view.viewId]
          ? view.sharedEmails
          : view.sharedEmails.slice(0, emailsToDisplay);
        return {
          key: view.viewId,
          version: (
            <Description
              title={i18n.t("label.shareholders")}
              description={event && formatEventLabel(event)}
            />
          ),
          createdBy: (
            <TooltipV2
              content={`${createdBy?.name} (${
                createdBy?.passportNumber || createdBy?.refId
              })`}
            >
              <Badge color="blue" className="tw-whitespace-normal">
                {nameToInitials(createdBy?.name || "")}
              </Badge>
            </TooltipV2>
          ),
          created: (
            <p className="tw-whitespace-nowrap tw-text-sm">
              {new Date(view.createdAt).toLocaleDateString(getLocale())}
            </p>
          ),
          expiry: (
            <div className="tw-flex tw-items-center tw-gap-2 tw-whitespace-nowrap tw-text-sm">
              {new Date(view.expiryDate).toLocaleDateString(getLocale())}
            </div>
          ),
          emails: (
            <div className="tw-flex tw-flex-wrap tw-gap-2">
              {displayEmails.map(({ email, invitationSent }) => (
                <TooltipV2
                  content={
                    !invitationSent
                      ? i18n.t("views.column.emails.error")
                      : undefined
                  }
                  key={email}
                >
                  <Badge
                    color={invitationSent ? "blue" : "none"}
                    key={email}
                    className="tw-flex tw-items-center tw-gap-1"
                  >
                    {email}
                    {!invitationSent && <Info size={20} />}
                  </Badge>
                </TooltipV2>
              ))}
              {displayEmails.length < view.sharedEmails.length && (
                <button
                  type="button"
                  onClick={() =>
                    setExpandedEmails((prev) => ({
                      ...prev,
                      [view.viewId]: true,
                    }))
                  }
                >
                  <Badge color="blue">
                    +{view.sharedEmails.length - displayEmails.length}
                  </Badge>
                </button>
              )}
              {expandedEmails[view.viewId] &&
                view.sharedEmails.length > emailsToDisplay && (
                  <button
                    type="button"
                    onClick={() =>
                      setExpandedEmails((prev) => ({
                        ...prev,
                        [view.viewId]: false,
                      }))
                    }
                  >
                    <Badge>{i18n.t("label.showLess")}</Badge>
                  </button>
                )}
            </div>
          ),
          actions: isTabletOrMobileDevice ? (
            <div className="tw-flex tw-gap-2">
              {new Date(view.expiryDate) < now && (
                <Button onClick={(e) => e.stopPropagation()}>
                  <Link
                    to={`/external/${currentCompany.orgNumber}/views/${view.viewId}`}
                    target="_blank"
                  >
                    <ArrowSquareOut />
                  </Link>
                </Button>
              )}
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setEditView(view.viewId);
                }}
              >
                <PencilIcon />
              </Button>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  deleteMutation.mutate(view.viewId);
                  setExpanded({});
                }}
              >
                <TrashIcon />
              </Button>
            </div>
          ) : (
            <Menu as="div" data-testid="view-actions">
              <Menu.Button variant="clean" isDropdown={false}>
                <ThreeDotsIcon />
              </Menu.Button>
              <Menu.Items align="bottomRight" className="tw-absolute tw-z-50">
                {new Date(view.expiryDate) > now && (
                  <Link
                    to={`/external/${currentCompany.orgNumber}/views/${view.viewId}`}
                    target="_blank"
                  >
                    <Menu.Item>{i18n.t("views.actions.preview")}</Menu.Item>
                  </Link>
                )}
                {canEdit && (
                  <>
                    <Menu.Item onClick={() => setEditView(view.viewId)}>
                      {i18n.t("views.actions.edit")}
                    </Menu.Item>
                    <Menu.Item
                      className="tw-text-error"
                      onClick={() => deleteMutation.mutate(view.viewId)}
                    >
                      {i18n.t("views.actions.delete")}
                    </Menu.Item>
                  </>
                )}
              </Menu.Items>
            </Menu>
          ),
        };
      })}
      expandedRows={expanded}
      setExpandedRows={setExpanded}
      sortBy={sortBy}
      setSortBy={setSortBy}
    />
  );
};

export { ViewsTable };
