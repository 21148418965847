import type { EntitiesMap } from "../../types/models/entities";
import type { Shareblock, ShareType } from "../../types/models/shares";
import { sumRanges } from "../../utils/shares";

type SortBy = "blockNumber-asc" | "blockNumber-desc" | "name-asc" | "name-desc";

const getTotalSharesByType = (
  blocks: {
    start: number;
    end: number;
    type: string;
    cancelled: boolean;
  }[]
) => {
  const totalSharesByTypeMap = new Map<string, number>();
  blocks
    .filter((b) => !b.cancelled)
    .reduce((result, block) => {
      const { type } = block;
      const totalBlocksForType = sumRanges([block]) + (result.get(type) || 0);
      result.set(type, totalBlocksForType);

      return result;
    }, totalSharesByTypeMap);

  const totalSharesByType: Record<string, number> =
    Object.fromEntries(totalSharesByTypeMap);

  return totalSharesByType;
};

const addSharesToSharesTypesData = (
  shareTypes: ShareType[],
  totalSharesByType: Record<string, number>
): (ShareType & { shares: number })[] => {
  return shareTypes
    .map((type) => ({
      ...type,
      shares: totalSharesByType[type.name] || 0,
    }))
    .filter((x) => x.shares > 0);
};

const sortBlocks = (
  blocks: Shareblock[],
  key: SortBy,
  entitiesMap: EntitiesMap
): Shareblock[] => {
  const [sortBy, sortOrder] = key.split("-");
  const isAscending = sortOrder === "asc";
  if (sortBy === "blockNumber") {
    return blocks.sort((a, b) =>
      isAscending ? a.start - b.start : b.start - a.start
    );
  }

  if (Object.keys(entitiesMap).length === 0) {
    throw Error("No entities map");
  }

  return blocks.sort((a, b) => {
    if (!entitiesMap[a.holder.id]) {
      throw Error(
        `Shareholder ${a.holder.id} couldn't be found in ${Object.keys(
          entitiesMap
        )}`
      );
    }

    if (!entitiesMap[b.holder.id]) {
      throw Error(
        `Shareholder ${b.holder.id} couldn't be found in ${Object.keys(
          entitiesMap
        )}`
      );
    }

    return (
      (isAscending ? 1 : -1) *
      // @ts-expect-error TODO: Prepare the data before rendering and use a fallback value (e.g. 'N/A')
      entitiesMap[a.holder.id].name.localeCompare(entitiesMap[b.holder.id].name)
    );
  });
};

export { addSharesToSharesTypesData, getTotalSharesByType, sortBlocks };
export type { SortBy };
