import { Dialog as HeadlessDialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { clsxm } from "../../../utils/tailwind";
import { Button } from "../Button";
import { CrossIcon } from "../icons";

const sizes = { sm: "tw-max-w-lg", md: "tw-max-w-2xl", lg: "tw-max-w-6xl" };

type DialogProps = {
  isOpen?: boolean;
  canClose?: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  actions?: React.ReactNode;
  title: string;
  isLoading?: boolean;
  size?: keyof typeof sizes;
  className?: string;
};

const Dialog = ({
  isOpen,
  canClose = true,
  onClose = () => {},
  children,
  title,
  actions,
  isLoading,
  size = "sm",
  className,
}: DialogProps) => {
  const { t } = useTranslation();
  const handleClose = () => {
    if (isLoading || !canClose) {
      return;
    }
    onClose();
  };
  const sizeClassname = sizes[size];

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <HeadlessDialog
        as="div"
        className="tw-fixed tw-inset-0 tw-z-50 tw-max-h-screen tw-overflow-y-auto"
        onClose={handleClose}
      >
        <div className="tw-min-h-screen tw-text-center">
          <Transition.Child
            as={Fragment}
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0"
            enterTo="tw-opacity-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
          >
            <div className="tw-fixed tw-inset-0 tw-bg-gray-800 tw-bg-opacity-30" />
          </Transition.Child>
          <span
            className="tw-hidden tw-h-screen tw-align-middle md:tw-inline-block"
            aria-hidden="true"
          >
            {"\u200B"}
          </span>
          <Transition.Child
            as={Fragment}
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0 tw-scale-95"
            enterTo="tw-opacity-100 tw-scale-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100 tw-scale-100"
            leaveTo="tw-opacity-0 tw-scale-95"
          >
            <HeadlessDialog.Panel
              className={clsxm(
                "tw-my-8 tw-inline-block tw-w-full tw-transform tw-overflow-hidden tw-rounded-md tw-bg-white tw-text-left tw-align-middle tw-shadow-lg tw-transition-all",
                sizeClassname,
                className // add the external className here
              )}
            >
              <HeadlessDialog.Title
                as="h4"
                className="tw-pl-4 tw-pr-12 tw-pt-4 tw-font-medium"
              >
                {title}
              </HeadlessDialog.Title>
              <div className="tw-max-h-[calc(100vh-12rem)] tw-overflow-auto tw-px-4 tw-pb-2 tw-pt-0">
                {children}
              </div>
              {actions && (
                <div className="tw-flex tw-justify-end tw-space-x-2 tw-p-4">
                  {actions}
                </div>
              )}
              {canClose && (
                <div className="tw-absolute tw-right-0 tw-top-0">
                  <Button
                    onClick={handleClose}
                    variant="clean"
                    disabled={isLoading}
                  >
                    <span className="tw-sr-only">{t("label.close")}</span>
                    <CrossIcon className="tw-h-6 tw-w-6" />
                  </Button>
                </div>
              )}
            </HeadlessDialog.Panel>
          </Transition.Child>
        </div>
      </HeadlessDialog>
    </Transition>
  );
};

export { Dialog };
export type { DialogProps };
