import { RadioGroup as TailwindRadioGroup } from "@headlessui/react";

import type { ExtractProps } from "../../../types";
import { CircleIcon, RadioButtonIcon } from "../icons";

const RadioGroup = (props: ExtractProps<typeof TailwindRadioGroup>) => (
  <TailwindRadioGroup {...props} />
);

RadioGroup.Label = (props: ExtractProps<typeof TailwindRadioGroup.Label>) => (
  <TailwindRadioGroup.Label {...props} />
);

RadioGroup.Option = (props: ExtractProps<typeof TailwindRadioGroup.Option>) => (
  <TailwindRadioGroup.Option
    className="ui-active:tw-custom-focus-visible tw-flex tw-w-full tw-cursor-pointer tw-gap-2 tw-rounded-md tw-border tw-border-solid tw-p-4 ui-checked:tw-border-primary ui-not-checked:tw-border-gray-500"
    {...props}
  />
);

type OptionContentProps = {
  active?: boolean;
  checked?: boolean;
  children: React.ReactNode;
};
RadioGroup.OptionContent = ({
  checked = false,
  children,
}: OptionContentProps) => {
  const Icon = checked ? RadioButtonIcon : CircleIcon;

  return (
    <>
      <div>
        <Icon className="tw-h-5 tw-w-5" weight={checked ? "fill" : "regular"} />
      </div>
      {children}
    </>
  );
};

export { RadioGroup };
