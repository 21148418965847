import _ from "lodash";
import { useState } from "react";

import { useActiveApprovalRuleQuery } from "../../../api/blockchain/company";
import { useUsersQuery } from "../../../api/blockchain/users";
import {
  UpdateCompanyPolicyRequestType,
  useUpdateCompanyPolicyMutation,
} from "../../../api/rest/administration";
import { useUploadPolicyAttachmentsMutation } from "../../../api/rest/attachments";
import { Attachment } from "../../../components/design-system/Attachment";
import { Button } from "../../../components/design-system/Button";
import { FormErrorList } from "../../../components/design-system/FormGroup";
import { Loading } from "../../../components/design-system/Loading";
import { notify } from "../../../components/design-system/Notifications";
import { useCurrentCompany } from "../../../context/account";
import {
  getCurrentPolicy,
  getPercentageFromRule,
} from "../../companies/[companyId]/settings/policy/change";
import {
  ApprovalPolicyForm,
  ChangeApprovalPolicy,
} from "../../companies/[companyId]/settings/policy/change/ChangeApprovalPolicy";

const UpdateCompanyPolicyPage = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [confirmed, setConfirm] = useState(false);
  const currentCompany = useCurrentCompany();
  const usersQuery = useUsersQuery(currentCompany?.orgNumber || "");
  const activeApprovalRuleQuery = useActiveApprovalRuleQuery(
    currentCompany?.orgNumber || ""
  );
  const policy = getCurrentPolicy(activeApprovalRuleQuery.data) || {
    rule: "None",
    approvers: [],
    percentage: undefined,
  };

  const mutation = useUpdateCompanyPolicyMutation(
    currentCompany?.orgNumber || "",
    { onSuccess: () => uploadFilesMutation.mutate({ files }) }
  );

  const uploadFilesMutation = useUploadPolicyAttachmentsMutation(
    currentCompany?.orgNumber || "",
    { onSuccess: () => notify("Successfully updated", { type: "success" }) }
  );

  const getPolicy = (
    form: ApprovalPolicyForm
  ): UpdateCompanyPolicyRequestType => {
    if (["Percentage", "Any", "All"].includes(form.rule)) {
      const percentage = getPercentageFromRule(form);
      if (percentage === null) {
        throw Error("Null percentage");
      }
      return {
        type: "BoardPercentage",
        percentage: Math.floor(percentage * 1000),
        users: [],
      };
    }
    if (form.rule === "Manual") {
      if (!form.approvers) {
        throw Error("Invalid users");
      }
      return {
        type: "SpecificUsers",
        users: form.approvers,
      };
    }
    throw Error("Invalid policy");
  };

  if (
    !currentCompany ||
    activeApprovalRuleQuery.isLoading ||
    usersQuery.isLoading
  ) {
    return <Loading />;
  }

  return (
    <div className="tw-flex tw-max-w-lg tw-flex-col tw-gap-4">
      <ChangeApprovalPolicy
        currentCompany={currentCompany}
        formId="policy"
        users={usersQuery.data ?? []}
        currentValues={policy}
        onSubmit={(data) => {
          if (!confirmed) {
            alert("Please confirm you are authorized");
            return;
          }
          if (files.length === 0) {
            alert("Please attach supporting files");
            return;
          }
          if (_.isEqual(data, policy)) {
            alert("Rule did not change");
            return;
          }
          const newPolicy = getPolicy(data);
          mutation.mutate(newPolicy);
        }}
      />
      <div className="tw-flex tw-gap-2">
        <label htmlFor="confirm">
          I verify I am authorized to override policy for {currentCompany.name}{" "}
          ({currentCompany.orgNumber})
        </label>
        <input
          type="checkbox"
          id="confirm"
          name="confirm"
          checked={confirmed}
          onClick={() => setConfirm(!confirmed)}
        />
      </div>
      <Attachment files={files} setFiles={setFiles} />
      {mutation.error && <FormErrorList error={mutation.error} />}
      <Button type="submit" form="policy" isLoading={mutation.isLoading}>
        Submit
      </Button>
    </div>
  );
};

export { UpdateCompanyPolicyPage };
