import { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";

import { useParentEventsQuery } from "../../../api/blockchain/events";
import { useUploadAttachmentsMutation } from "../../../api/rest/attachments";
import { useSession } from "../../../context/session";
import IncreaseCapital from "../../../pages/CompanyShares/IncreaseCapital";
import { IssueShares } from "../../../pages/CompanyShares/IssueShares";
import PledgedSharesUpdate from "../../../pages/CompanyShares/PledgedSharesUpdate";
import Reclassification from "../../../pages/CompanyShares/Reclassification";
import ReduceCapital from "../../../pages/CompanyShares/ReduceCapital";
import ReverseSplitShares from "../../../pages/CompanyShares/ReverseSplitShares";
import ShareCertificateUpdate from "../../../pages/CompanyShares/ShareCertificatesUpdate";
import ShareTypeUpdate from "../../../pages/CompanyShares/ShareTypeUpdate";
import SplitShares from "../../../pages/CompanyShares/SplitShares";
import TransferShares from "../../../pages/CompanyShares/TransferShares";
import { CompanyInformation } from "../../../types/models/administration";
import { CompanyInvolvement } from "../../../types/models/company";
import { Attachment } from "../../design-system/Attachment";
import {
  CertificateIcon,
  CrossIcon,
  IncreaseCapitalIcon,
  PledgedIcon,
  ReclassificationIcon,
  ReduceCapitalIcon,
  ShareClassUpdateIcon,
  ShareIssueIcon,
  SplitIcon,
  TransferIcon,
} from "../../design-system/icons";
import { EventFormData } from "../EventsWizard.utils";

type EventCardProps = {
  title: string;
  description: string;
  icon: ReactNode;
  onClick: () => void;
};

const EventCard = ({ title, description, icon, onClick }: EventCardProps) => {
  return (
    <button
      className="tw-flex tw-w-[370px] tw-gap-4 tw-rounded tw-border tw-p-4"
      type="button"
      onClick={onClick}
    >
      {icon}
      <div className="tw-text-left">
        <h3 className="tw-text-sm tw-font-medium">{title}</h3>
        <p className="tw-text-wrap tw-text-sm tw-text-secondary">
          {description}
        </p>
      </div>
    </button>
  );
};

type SelectEventProps = {
  currentCompany: CompanyInformation | CompanyInvolvement;
  onSuccess: () => void;
  setFormData: Dispatch<SetStateAction<EventFormData>>;
  selectedEvent: JSX.Element | undefined;
  setSelectedEvent: (event: JSX.Element | undefined) => void;
};

const SelectEvent = ({
  onSuccess: success,
  setFormData,
  selectedEvent,
  setSelectedEvent,
  currentCompany,
}: SelectEventProps) => {
  const i18n = useTranslation();
  const { user } = useSession();
  const [files, setFiles] = useState<File[]>([]);
  const eventsQuery = useParentEventsQuery({
    orgNumber: currentCompany.orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  });

  const uploadFilesMutation = useUploadAttachmentsMutation(
    currentCompany.orgNumber,
    { onSuccess: success }
  );

  const onSuccess = (eventId: string) => {
    eventsQuery.refetch();
    setFiles((f) => {
      if (f.length > 0) {
        uploadFilesMutation.mutate({ files: f, eventId, userId: user!.id });
      } else {
        success();
      }
      return f;
    });
  };

  const events = [
    {
      type: "transfer",
      title: i18n.t("events.transfer.title"),
      description: i18n.t("events.transfer.description"),
      icon: TransferIcon,
      formId: "transfer-shares-form",
      primary: true,
      page: (
        <TransferShares
          currentCompany={currentCompany}
          onSuccess={onSuccess}
          setFormData={setFormData}
        />
      ),
    },
    {
      type: "issueshares",
      title: i18n.t("events.issue.title"),
      description: i18n.t("events.issue.description"),
      icon: ShareIssueIcon,
      primary: true,
      page: (
        <IssueShares
          currentCompany={currentCompany}
          onSuccess={onSuccess}
          setFormData={setFormData}
        />
      ),
    },
    {
      type: "split",
      title: i18n.t("events.split.title"),
      description: i18n.t("events.split.description"),
      icon: SplitIcon,
      primary: true,
      page: (
        <SplitShares
          currentCompany={currentCompany}
          onSuccess={onSuccess}
          setFormData={setFormData}
        />
      ),
    },
    {
      type: "reversesplit",
      title: i18n.t("events.reverseSplit.title"),
      description: i18n.t("events.reverseSplit.description"),
      icon: CrossIcon,
      page: (
        <ReverseSplitShares
          currentCompany={currentCompany}
          onSuccess={onSuccess}
          setFormData={setFormData}
        />
      ),
    },
    {
      type: "reducecapital",
      title: i18n.t("events.reduceCapital.title"),
      description: i18n.t("events.reduceCapital.description"),
      icon: ReduceCapitalIcon,
      page: (
        <ReduceCapital
          currentCompany={currentCompany}
          onSuccess={onSuccess}
          setFormData={setFormData}
        />
      ),
    },
    {
      type: "increasecapital",
      title: i18n.t("events.increaseCapital.title"),
      description: i18n.t("events.increaseCapital.description"),
      icon: IncreaseCapitalIcon,
      page: (
        <IncreaseCapital
          currentCompany={currentCompany}
          onSuccess={onSuccess}
          setFormData={setFormData}
        />
      ),
    },
    {
      type: "reclassification",
      title: i18n.t("events.reclassification.title"),
      description: i18n.t("events.reclassification.description"),
      icon: ReclassificationIcon,
      page: (
        <Reclassification
          currentCompany={currentCompany}
          onSuccess={onSuccess}
          setFormData={setFormData}
        />
      ),
    },
    {
      type: "sharetypeupdate",
      title: i18n.t("events.shareTypeUpdate.title"),
      description: i18n.t("events.shareTypeUpdate.description"),
      icon: ShareClassUpdateIcon,
      page: (
        <ShareTypeUpdate
          currentCompany={currentCompany}
          onSuccess={onSuccess}
          setFormData={setFormData}
        />
      ),
    },
    {
      type: "sharecertificateupdate",
      title: i18n.t("events.shareCertificateUpdate.title"),
      description: i18n.t("events.shareCertificateUpdate.description"),
      icon: CertificateIcon,
      page: (
        <ShareCertificateUpdate
          currentCompany={currentCompany}
          onSuccess={onSuccess}
          setFormData={setFormData}
        />
      ),
    },
    {
      type: "pledgedsharesupdate",
      title: i18n.t("events.pledgedSharesUpdate.title"),
      description: i18n.t("events.pledgedSharesUpdate.description"),
      icon: PledgedIcon,
      page: (
        <PledgedSharesUpdate
          currentCompany={currentCompany}
          onSuccess={onSuccess}
          setFormData={setFormData}
        />
      ),
    },
  ];

  if (selectedEvent) {
    return (
      <div className="tw-flex tw-flex-col tw-gap-6 tw-pb-4 max-md:tw-p-4">
        {selectedEvent}
        <Attachment files={files} setFiles={setFiles} />
      </div>
    );
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-pb-4 max-md:tw-p-4">
      <h1 className="tw-text-xl tw-font-medium">{i18n.t("events.add")}</h1>
      <div className="tw-flex tw-flex-wrap tw-gap-2">
        {events.map((e) => (
          <EventCard
            key={e.type}
            title={e.title}
            description={e.description}
            icon={<e.icon className="tw-h-6 tw-w-6" />}
            onClick={() => {
              setSelectedEvent(e.page);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export { SelectEvent };
