import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import BankIdLogin from "../../auth/BankId/BankIdLogin";
import MockedBankIdLogin from "../../auth/BankId/MockedBankIdLogin";
import MockedTruidLogin from "../../auth/Truid/MockedTruidLogin";
import TruidLogin from "../../auth/Truid/TruidLogin";
import { Button } from "../../components/design-system/Button";
import { BankIdIcon, TruidIcon } from "../../components/design-system/icons";
import { SignInFormLogo } from "../../components/SignInFormLogo";
import { featureToggles } from "../../config";
import { useSession } from "../../context/session";
import { useToggles } from "../../hooks/useToggles";

const SignInForm = () => {
  const [step, setStep] = useState<
    "bankId" | "Truid" | "initial" | "bankIdMock" | "TruidMock"
  >("initial");
  const i18n = useTranslation();
  const { isFeatureEnabled } = useToggles();

  const mockAuthEnabled = isFeatureEnabled(featureToggles.ENABLE_MOCK_AUTH);

  const { handleSignedIn } = useSession();

  const handleBankIdClick = () => {
    setStep("bankId");
  };

  const handleTruidClick = () => {
    setStep("Truid");
  };

  const handleMockClick = () => {
    setStep("bankIdMock");
  };

  const handleStartMockTruidClick = () => setStep("TruidMock");

  return (
    <div className="tw-w-full">
      {step === "initial" && (
        <div className="tw-flex tw-flex-col tw-gap-8 tw-text-center">
          <SignInFormLogo />
          <div>
            <h1 className="tw-font-medium ">{i18n.t("login.title")}</h1>
            <p className="tw-text-secondary">{i18n.t("login.tip")}</p>
          </div>
          <div className="tw-flex tw-flex-col tw-justify-center tw-gap-2 lg:tw-flex-row">
            <Button
              onClick={handleBankIdClick}
              variant="solid"
              color="primary"
              prefix={<BankIdIcon />}
            >
              {i18n.t("login.withBankId")}
            </Button>
            <Button
              variant="outline"
              color="secondary"
              onClick={handleTruidClick}
              prefix={
                <span>
                  <TruidIcon />
                </span>
              }
            >
              {i18n.t("login.Truid")}
            </Button>
            {mockAuthEnabled && (
              <Button
                color="danger"
                prefix={<BankIdIcon />}
                variant="outline"
                onClick={handleMockClick}
              >
                Mock
              </Button>
            )}
            {mockAuthEnabled && (
              <Button
                color="danger"
                prefix={<TruidIcon />}
                variant="outline"
                onClick={handleStartMockTruidClick}
              >
                Mock Truid
              </Button>
            )}
          </div>
        </div>
      )}
      {step === "TruidMock" && (
        <MockedTruidLogin
          onSuccess={(data) => handleSignedIn(data, "Truid")}
          onCancel={() => setStep("initial")}
        />
      )}
      {step === "bankId" && (
        <BankIdLogin
          onSuccess={(data) => handleSignedIn(data, "BankId")}
          onCancel={() => setStep("initial")}
        />
      )}
      {step === "Truid" && (
        <TruidLogin
          onSuccess={(data) => handleSignedIn(data, "Truid")}
          onCancel={() => setStep("initial")}
        />
      )}
      {step === "bankIdMock" && (
        <MockedBankIdLogin
          onSuccess={(data) => handleSignedIn(data, "BankId")}
          onCancel={() => setStep("initial")}
        />
      )}
      <p className="tw-mt-4 tw-text-center tw-text-sm tw-text-secondary">
        <Trans
          components={{
            termsLink: (
              <Link
                target="_blank"
                to="https://kvanta.com/terms"
                className="tw-underline"
              />
            ),
            privacyPolicyLink: (
              <Link
                target="_blank"
                to="https://kvanta.com/privacy-policy"
                className="tw-underline"
              />
            ),
          }}
          i18nKey="login.policy"
        />
      </p>
    </div>
  );
};

export default SignInForm;
