import { ShareType } from "@capchapdev/rell-api";
import { useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import {
  useLedgerQuery,
  useShareTypesQuery,
} from "../../../api/blockchain/company";
import { useEntitiesQuery } from "../../../api/rest/entities";
import { Description } from "../../../components/design-system/Description";
import { DistributionProgress } from "../../../components/design-system/DistributionProgress";
import { EmptyState } from "../../../components/design-system/EmptyState";
import { EntityItem } from "../../../components/design-system/EntityItem";
import { FormError } from "../../../components/design-system/FormGroup";
import { TableV2 } from "../../../components/design-system/TableV2";
import { AddShareBlock } from "../../../components/ShareBlocks/Add";
import { ShareBlockRowControls } from "../../../components/ShareBlocks/List";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import { formatNumber } from "../../../utils/format";
import { calcSumWithinRange } from "../../../utils/shares";
import type { ShareIssueFormState } from "./types";

type ShareBlocksProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  form: UseFormReturn<ShareIssueFormState>;
};

const ShareBlocks = ({ currentCompany, form }: ShareBlocksProps) => {
  const [expandedRows, setExpandedRows] = useState<Record<number, boolean>>([]);
  const i18n = useTranslation();
  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const entitiesData = entitiesQuery.data || [];
  const entitiesMap = Object.fromEntries(entitiesData.map((e) => [e.id, e]));
  const ledgerQuery = useLedgerQuery(currentCompany.orgNumber, "");
  const numberOfShares = ledgerQuery.data?.shares.lastNumber || 0;

  const { watch, control, formState } = form;
  const data = watch();

  const shareTypesQuery = useShareTypesQuery(currentCompany.orgNumber, "");
  const shareTypes = shareTypesQuery.data || [];
  const shareClasses: ShareType[] = [...shareTypes, ...data.shareClasses];

  const shareClassTotals = data.shareRanges
    .map((b) => b.type)
    .reduce((prev, shareType) => {
      const blocksForType = data.shareRanges.filter(
        (block) => block.type === shareType
      );
      const totalSharesInType = blocksForType.reduce(
        (p, curr) => p + calcSumWithinRange(curr),
        0
      );

      return {
        [shareType]: totalSharesInType,
        ...prev,
      };
    }, {} as Record<string, number>);

  const diff =
    data.shareRanges.reduce(
      (prev, curr) => prev + calcSumWithinRange(curr),
      0
    ) - (data.shareCapital.totalShares || 0);

  return (
    <div className="md:tw-rounded md:tw-border md:tw-p-4">
      <div className="tw-flex tw-flex-col tw-justify-between tw-pb-4 md:tw-flex-row md:tw-items-center">
        <h2 className="tw-text-lg tw-font-medium">
          {i18n.t("events.issue.shareBlocks.title")}
        </h2>
        <DistributionProgress diff={diff} />
      </div>
      <div>
        <Controller
          control={control}
          name="shareRanges"
          render={({ field: { onChange, value } }) => (
            <div className="tw-flex tw-flex-col tw-gap-4">
              <div>
                <TableV2
                  columns={[
                    {
                      name: "range",
                      title: i18n.t("label.shareBlock"),
                      sortable: false,
                      key: true,
                    },
                    {
                      name: "entity",
                      title: i18n.t("label.shareholder"),
                      sortable: false,
                      key: true,
                    },
                    {
                      name: "controls",
                      title: "",
                      sortable: false,
                      key: !isTabletOrMobileDevice,
                    },
                  ]}
                  data={value.map((block, i) => {
                    const entity = entitiesMap[block.holderId];

                    return {
                      key: block.start.toString(),
                      range: (
                        <Description
                          title={`${formatNumber(block.start)} - ${formatNumber(
                            block.end
                          )}`}
                          description={`${formatNumber(
                            calcSumWithinRange(block)
                          )} ${block.type}`}
                        />
                      ),
                      entity: entity ? (
                        <EntityItem
                          value={entity}
                          displayIcon={false}
                          className={
                            isTabletOrMobileDevice ? "tw-text-right" : undefined
                          }
                          flagPosition={
                            isTabletOrMobileDevice ? "left" : "right"
                          }
                        />
                      ) : undefined,
                      controls: (
                        <ShareBlockRowControls
                          index={i}
                          block={block}
                          items={value}
                          onChange={onChange}
                          entitiesQuery={entitiesQuery}
                          currentCompany={currentCompany}
                          shareTypes={shareClasses}
                        />
                      ),
                    };
                  })}
                  expandedRows={expandedRows}
                  setExpandedRows={setExpandedRows}
                />
                {value.length === 0 && (
                  <EmptyState
                    title={i18n.t("events.issue.shareBlocks.empty.title")}
                    description={i18n.t(
                      "events.issue.shareBlocks.empty.description"
                    )}
                  />
                )}
                {Object.keys(shareClassTotals).length > 0 && (
                  <div className="tw-flex tw-flex-col tw-p-4 tw-text-sm tw-text-secondary">
                    {Object.entries(shareClassTotals).map(([name, total]) => (
                      <div key={name}>
                        {total} {name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <FormError className="tw-p-4">
                {formState.errors.shareRanges?.message}
              </FormError>
              <AddShareBlock
                currentCompany={currentCompany}
                entitiesQuery={entitiesQuery}
                shareTypes={shareClasses}
                onSuccess={(newValue) => {
                  const previousEnd = value.at(-1)?.end || numberOfShares;
                  onChange([
                    ...value,
                    {
                      start: previousEnd + 1,
                      end: previousEnd + (newValue.numberOfShares || 0),
                      type: newValue.type || "",
                      holderId: newValue.holderId || "",
                    },
                  ]);
                }}
                variant="outline"
                color="secondary"
              />
            </div>
          )}
          rules={{ required: i18n.t("error.validation.required") }}
        />
      </div>
    </div>
  );
};

export default ShareBlocks;
