import { ArrowSquareOut, ThumbsUp } from "@phosphor-icons/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button } from "../../components/design-system/Button";
import { Description } from "../../components/design-system/Description";
import { Disclosure } from "../../components/design-system/Disclosure";
import { Loading } from "../../components/design-system/Loading";
import type {
  CompanyInformation,
  CompanyShareCapitalHistory,
} from "../../types/models/administration";
import type { CompanyInvolvement } from "../../types/models/company";
import { TParentEvent } from "../../types/models/events";
import { getFormattedDate } from "../../utils/date";
import { formatCurrency, formatNumber } from "../../utils/format";
import banner from "./banner.svg";
import {
  calculateRatio,
  useCompanyDataComparison,
} from "./EventsValidation.utils";

type EventsValidationProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
};

const EventsValidation: React.FunctionComponent<EventsValidationProps> = ({
  currentCompany,
}) => {
  const i18n = useTranslation();
  const [expanded, setExpanded] = useState<string[]>([]);
  const companyDataMismatch = useCompanyDataComparison(
    currentCompany.orgNumber
  );
  const ledgerOnly =
    companyDataMismatch?.filter((x) => x.ledger && !x.companyData) || [];
  const companyDataOnly =
    companyDataMismatch?.filter((x) => !x.ledger && x.companyData) || [];

  if (!companyDataMismatch) {
    return <Loading />;
  }

  const eventTitles = {
    CompanyFoundation: i18n.t("events.companyFoundation.title"),
    ShareIssue: i18n.t("events.issue.title"),
    IncreaseCapitalBonusIssue: i18n.t("events.bonusIssue.title"),
    DecreaseCapitalCancelShares: i18n.t(
      "events.decreaseCapitalCancelShares.title"
    ),
    DecreaseCapital: i18n.t("events.reduceCapital.title"),
    ShareSplit: i18n.t("events.split.title"),
    ReverseShareSplit: i18n.t("events.reverseSplit.title"),
  };
  type EventType = keyof typeof eventTitles;
  function isEventType(value: string): value is EventType {
    return value in eventTitles;
  }

  const getEventCard = (
    event: TParentEvent | CompanyShareCapitalHistory,
    date: string,
    details: { [key: string]: string }
  ) => {
    if (!isEventType(event.type)) {
      throw Error(`Event type ${event.type} not supported`);
    }
    const title = eventTitles[event.type];
    return (
      <div className="tw-rounded tw-bg-white tw-p-4 tw-text-primary">
        <Disclosure className="tw-flex tw-flex-col tw-gap-2">
          <div className="tw-flex tw-items-center tw-justify-between">
            <div className="tw-text-sm">
              <p>{title}</p>
              <p className="tw-text-secondary">{getFormattedDate(date)}</p>
            </div>
            <div>
              <Disclosure.Button
                open={expanded.includes(event.id)}
                onClick={() =>
                  expanded.includes(event.id)
                    ? setExpanded(expanded.filter((x) => x !== event.id))
                    : setExpanded([...expanded, event.id])
                }
              />
            </div>
          </div>
          <Disclosure.Panel>
            <div className="tw-flex tw-flex-wrap tw-gap-4">
              {Object.entries(details).map(([k, v]) => (
                <Description
                  title={k}
                  description={v}
                  key={k}
                  theme="grayBlack"
                />
              ))}
            </div>
          </Disclosure.Panel>
        </Disclosure>
      </div>
    );
  };

  return (
    <>
      <div className="tw-flex tw-flex-col tw-gap-6 max-md:tw-pt-4">
        <div className="tw-bg-gradient-to-r tw-from-[#f3e8ff] tw-to-[#f7f5fa] tw-p-6">
          <img src={banner} className="tw-mx-auto" alt="banner" />
        </div>
        <div>
          <h1 className="tw-text-2xl tw-font-medium tw-text-primary">
            {i18n.t("validate.title")}
          </h1>
          <p className="tw-text-sm tw-text-primary">
            {i18n.t("validate.description")}
          </p>
        </div>
        {ledgerOnly.length === 0 && companyDataOnly.length === 0 && (
          <div className="tw-flex tw-flex-col tw-gap-4 tw-rounded tw-border tw-bg-green-50 tw-p-6 tw-text-green-900">
            <div>
              <h2 className="tw-text-xl tw-font-medium">
                {i18n.t("validate.valid.title")}
              </h2>
              <p className="tw-text-sm">
                {i18n.t("validate.valid.description")}
              </p>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-2">
              <p className="tw-flex tw-items-center tw-gap-2 tw-text-sm">
                <ThumbsUp />
                {i18n.t("validate.valid.description.ledger")}
              </p>
            </div>
          </div>
        )}
        {companyDataOnly.length > 0 && (
          <div className="tw-flex tw-flex-col tw-gap-4 tw-rounded tw-border tw-bg-amber-100 tw-p-6 tw-text-amber-900">
            <div>
              <h2 className="tw-text-xl tw-font-medium">
                {i18n.t("validate.ledger.title")}
              </h2>
              <p className="tw-text-sm">
                {i18n.t("validate.ledger.description")}
              </p>
            </div>
            {companyDataOnly.map(
              ({ companyData }) =>
                companyData &&
                getEventCard(
                  companyData,
                  companyData.decisionDate || companyData.date,
                  {
                    [i18n.t("validate.data.caseId")]: companyData.id,
                    ...(companyData.changes.shares && {
                      [i18n.t("validate.data.shares")]: formatNumber(
                        companyData.changes.shares
                      ),
                    }),
                    ...(companyData.changes.capital && {
                      [i18n.t("validate.data.capital")]: formatCurrency(
                        companyData.changes.capital,
                        "SEK"
                      ),
                    }),
                    ...(companyData.payment && {
                      [i18n.t("validate.data.investment")]: formatCurrency(
                        companyData.payment,
                        "SEK"
                      ),
                    }),
                    ...(companyData.type === "ShareSplit" && {
                      [i18n.t("validate.data.ratio")]: calculateRatio(
                        companyData.shares - companyData.changes.shares,
                        companyData.changes.shares
                      ),
                    }),
                  }
                )
            )}
            <Link to={`/companies/${currentCompany.orgNumber}/events`}>
              <Button color="primary" variant="solid">
                {i18n.t("validate.ledger.button")}
              </Button>
            </Link>
          </div>
        )}
        {ledgerOnly.length > 0 && (
          <div className="tw-flex tw-flex-col tw-gap-4 tw-rounded tw-border tw-bg-amber-100 tw-p-6 tw-text-amber-900">
            <div>
              <h2 className="tw-text-xl tw-font-medium">
                {i18n.t("validate.bv.title")}
              </h2>
              <p className="tw-text-sm">{i18n.t("validate.bv.description")}</p>
            </div>
            {ledgerOnly.map(
              ({ ledger }) =>
                ledger &&
                getEventCard(ledger, ledger.date.split(".")[0]!, {
                  ...("shares" in ledger &&
                    "total" in ledger.shares && {
                      [i18n.t("validate.data.shares")]: formatNumber(
                        ledger.shares.total
                      ),
                    }),
                  ...("shares" in ledger &&
                    "capital" in ledger.shares && {
                      [i18n.t("validate.data.capital")]: formatCurrency(
                        parseFloat(ledger.shares.capital),
                        "SEK"
                      ),
                    }),
                  ...("shares" in ledger &&
                    "investment" in ledger.shares &&
                    parseFloat(ledger.shares.investment) !== 0 && {
                      [i18n.t("validate.data.investment")]: formatCurrency(
                        parseFloat(ledger.shares.investment),
                        "SEK"
                      ),
                    }),
                  ...((ledger.type === "ShareSplit" ||
                    ledger.type === "ReverseShareSplit") && {
                    [i18n.t(
                      "validate.data.ratio"
                    )]: `${ledger.ratio.x}:${ledger.ratio.y}`,
                  }),
                })
            )}
            <Link to="https://bolagsverket.se/" target="_blank">
              <Button
                color="primary"
                variant="solid"
                className="tw-flex tw-gap-2"
              >
                Bolagsverket.se <ArrowSquareOut />
              </Button>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export { EventsValidation };
