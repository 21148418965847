import { useTranslation } from "react-i18next";
import { Link, Route, Routes, useParams } from "react-router-dom";

import { useAdminCompaniesQuery } from "../../../api/blockchain/administration";
import { PageWrapper } from "../../../components/PageWrapper";
import * as monitoring from "../../../utils/monitoring";
import { UpdateCompanyStatusPage } from "../../companies/[companyId]/settings/update-status";
import { UpdateCompanyPolicyPage } from "../UpdatePolicy";

const SentryRoutes = monitoring.withSentryReactRouterV6Routing(Routes);

const CompanyAdministrationPage = () => {
  const { companyId } = useParams();
  const companiesQuery = useAdminCompaniesQuery();
  const currentCompany = (companiesQuery.data || []).find(
    (company) => companyId === company.orgNumber
  );
  const i18n = useTranslation();
  if (!currentCompany) {
    return null;
  }

  return (
    <PageWrapper data-testid="administration-details">
      <header className="tw-flex tw-justify-between tw-pb-3">
        <h4>
          <Link to="..">{i18n.t("label.administration")}</Link>
          {" / "}
          {currentCompany.name}
        </h4>
      </header>
      <SentryRoutes>
        <Route path="update-status" element={<UpdateCompanyStatusPage />} />
        <Route path="update-policy" element={<UpdateCompanyPolicyPage />} />
      </SentryRoutes>
    </PageWrapper>
  );
};

export { CompanyAdministrationPage };
