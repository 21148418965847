import { useParentEventsQuery } from "../../api/blockchain/events";
import { useCompanyShareCapitalEventsQuery } from "../../api/rest/administration";
import { CompanyShareCapitalHistory } from "../../types/models/administration";
import { TParentEvent } from "../../types/models/events";

const areDatesClose = (a: string, b: string) => {
  const date1 = new Date(a);
  const date2 = new Date(b);

  const timeDifference = Math.abs(date2.getTime() - date1.getTime());
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  // Check if the difference is within 365 days (1 year)
  return daysDifference <= 365;
};

const findMatchingEvent = (
  event: TParentEvent,
  shareCapitalEvents: CompanyShareCapitalHistory[]
) => {
  switch (event.type) {
    case "CompanyFoundation":
    case "ShareIssue":
    case "IncreaseCapitalBonusIssue":
    case "DecreaseCapitalCancelShares":
      return shareCapitalEvents?.find(
        (b) =>
          b.type === event.type &&
          b.changes.capital === parseFloat(event.shares.capital) &&
          b.changes.shares === event.shares.total &&
          areDatesClose(b.decisionDate || b.date, event.date.split(".")[0]!)
      );
    case "DecreaseCapital":
      return shareCapitalEvents?.find(
        (b) =>
          b.type === event.type &&
          b.changes.capital === parseFloat(event.shares.capital) &&
          areDatesClose(b.decisionDate || b.date, event.date.split(".")[0]!)
      );
    case "ShareSplit":
      return shareCapitalEvents?.find(
        (b) =>
          b.type === event.type &&
          b.changes.shares === event.shares.total &&
          areDatesClose(b.decisionDate || b.date, event.date.split(".")[0]!)
      );
    case "ReverseShareSplit":
      return shareCapitalEvents?.find(
        (b) =>
          b.type === "ShareSplit" &&
          b.changes.shares === -event.shares.total &&
          areDatesClose(b.decisionDate || b.date, event.date.split(".")[0]!)
      );
    default:
      throw Error(`Unsupported event type ${event.type}`);
  }
};

const calculateRatio = (before: number, after: number) => {
  let a = 1;
  let b = after / before;

  // Multiply to make ratio whole numbers
  const decimalPart = b.toString().split(".")[1];
  if (decimalPart) {
    const decimalLength = decimalPart.length;
    a *= 10 ** decimalLength;
    b *= 10 ** decimalLength;
  }

  // Function to find GCD
  const gcd = (x: number, y: number): number => {
    return y === 0 ? x : gcd(y, x % y);
  };

  const divisor = gcd(a, b);
  return `${a / divisor}:${b / divisor}`;
};

const useCompanyDataComparison = (
  orgNumber: string | undefined
):
  | {
      ledger: TParentEvent | null | undefined;
      companyData: CompanyShareCapitalHistory | null | undefined;
    }[]
  | null => {
  const parentEventsQuery = useParentEventsQuery({
    orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  });
  const parentEvents = parentEventsQuery.data?.data.filter(
    (e) =>
      [
        "CompanyFoundation",
        "DecreaseCapital",
        "DecreaseCapitalCancelShares",
        "ShareIssue",
        "IncreaseCapitalBonusIssue",
        "ShareSplit",
        "ReverseShareSplit",
      ].includes(e.type) &&
      (e.status === "Approved" || e.status === "PendingRollback")
  );
  const shareCapitalEventsQuery = useCompanyShareCapitalEventsQuery(orgNumber);
  const shareCapitalEvents = shareCapitalEventsQuery.data?.history;

  if (parentEvents && shareCapitalEvents) {
    const eventsMatchedOnLedger = parentEvents.map((e) => {
      const matchingEvent = findMatchingEvent(e, shareCapitalEvents);
      return { ledger: e, companyData: matchingEvent };
    });

    // Remove potential duplicate matches
    const seenCompanyEventIds = new Set<string>();
    const duplicateCompanyEventIds = new Set<string>();
    eventsMatchedOnLedger.forEach((c) => {
      if (c.companyData && seenCompanyEventIds.has(c.companyData.id)) {
        duplicateCompanyEventIds.add(c.companyData.id);
      } else if (c.companyData) {
        seenCompanyEventIds.add(c.companyData.id);
      }
    });

    const eventsMatchesOnLedgerNoDuplicates = eventsMatchedOnLedger.map(
      (item) => {
        if (
          item.companyData &&
          duplicateCompanyEventIds.has(item.companyData.id)
        ) {
          // This means this companyData id has already been seen before, so delete both as we're not sure which to match
          return { ...item, companyData: undefined };
        }
        return item;
      }
    );

    const allFoundCompanyDataEvents = eventsMatchesOnLedgerNoDuplicates.map(
      (x) => x.companyData
    );
    const eventsMissingFromCompanyData = shareCapitalEvents
      .filter((e) => !allFoundCompanyDataEvents.includes(e))
      .map((x) => ({ ledger: null, companyData: x }));

    const combined: {
      ledger: TParentEvent | null | undefined;
      companyData: CompanyShareCapitalHistory | null | undefined;
    }[] = [
      ...eventsMissingFromCompanyData,
      ...eventsMatchesOnLedgerNoDuplicates,
    ];

    const combinedSorted = combined.sort((a, b) => {
      const aDate: `${string}.${string}` = a.ledger
        ? a.ledger.date
        : `${a.companyData!.decisionDate || a.companyData!.date}.0`;
      const bDate: `${string}.${string}` = b.ledger
        ? b.ledger.date
        : `${b.companyData!.decisionDate || b.companyData!.date}.0`;

      const [dateA, versionA] = aDate.split(".");
      const [dateB, versionB] = bDate.split(".");
      if (dateA === dateB) {
        // If dates are equal, check version
        return parseInt(versionA!, 10) > parseInt(versionB!, 10) ? -1 : 1;
      }
      return new Date(dateA!) > new Date(dateB!) ? -1 : 1;
    });

    return combinedSorted;
  }

  return null;
};

export { calculateRatio, useCompanyDataComparison };
