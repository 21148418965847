import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button } from "../../components/design-system/Button";
import { EmptyState } from "../../components/design-system/EmptyState";
import { PublicPageLayout } from "../../components/PublicPageLayout";

const AccessDenied = () => {
  const i18n = useTranslation();

  return (
    <PublicPageLayout>
      <div className="tw-w-full tw-text-center">
        <EmptyState
          title={i18n.t("externalView.accessDenied.title")}
          titleSize="2xl"
          description={i18n.t("externalView.accessDenied.description")}
          button={
            <Link to="https://www.kvanta.com">
              <Button>{i18n.t("externalView.accessDenied.link")}</Button>
            </Link>
          }
        />
      </div>
    </PublicPageLayout>
  );
};

export { AccessDenied };
