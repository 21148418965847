import { z } from "zod";

import type { DeepPartial } from "..";

const AddressSchema = z.object({
  line1: z.string(),
  line2: z.string().nullable().optional(),
  postalCode: z.string(),
  city: z.string(),
  region: z.string().nullable().optional(),
  countryCode: z.string(),
});

type Address = z.infer<typeof AddressSchema>;

const ContactSchema = z.object({
  email: z.string().nullable().optional(),
  phone: z.string().nullable().optional(),
  address: AddressSchema.nullable().optional(),
});

type Contact = z.infer<typeof ContactSchema>;

const EntityTypeSchema = z.enum(["Private", "Company"]);

type ShareEntityType = z.infer<typeof EntityTypeSchema>;

const EmailVerificationSchema = z.object({
  status: z.enum(["PENDING", "VERIFIED", "NOT_VERIFIED"]),
  createdAt: z.string().nullish(),
  lastUpdated: z.string().nullish(),
});
type EmailVerification = z.infer<typeof EmailVerificationSchema>;

const EntitySchema = z.object({
  id: z.string().uuid(),
  refId: z.string(),
  name: z.string(),
  type: EntityTypeSchema,
  countryCode: z.string(),
  contact: ContactSchema,
  birthDate: z.string().nullable().optional(),
  passportNumber: z.string().nullable().optional(),
  emailVerification: EmailVerificationSchema.nullish(),
  role: z.string().optional(),
});

const EntityWithChildrenSchema = EntitySchema.merge(
  z.object({
    children: z.array(EntitySchema).optional(),
    parents: z.array(EntitySchema).optional(),
  })
);

type Entity = z.infer<typeof EntityWithChildrenSchema>;

const EntityCoreSchema = z.object({
  id: z.string().uuid(),
  refId: z.string(),
  name: z.string(),
  type: EntityTypeSchema,
});

type EntityCore = z.infer<typeof EntityCoreSchema>;

const EntitiesSchema = z.array(EntityWithChildrenSchema);

type Entities = z.infer<typeof EntitiesSchema>;

type EntitiesMap = Record<string, Entity>;

const NewEntitySchema = z.object({
  id: z.string().uuid().optional(),
  refId: z.string().optional(),
  name: z.string(),
  type: EntityTypeSchema,
  countryCode: z.string(),
  contact: ContactSchema,
  passportNumber: z.string().nullable().optional(),
  birthDate: z.string().nullable().optional(),
});

type NewEntity = z.infer<typeof NewEntitySchema>;

type TEntitySuggestion = DeepPartial<Omit<Entity, "id">>;

const EntitySuggestionSchema = EntitySchema.omit({
  id: true,
})
  .nullable()
  .optional();

type EntitySuggestion = z.infer<typeof EntitySuggestionSchema>;

type EntitySuggestionOptions = Pick<Entity, "countryCode" | "refId" | "type">;

export type {
  Address,
  Contact,
  EmailVerification,
  Entities,
  EntitiesMap,
  Entity,
  EntityCore,
  EntitySuggestion,
  EntitySuggestionOptions,
  NewEntity,
  ShareEntityType,
  TEntitySuggestion,
};
export {
  AddressSchema,
  ContactSchema,
  EntitiesSchema,
  EntityCoreSchema,
  EntitySchema,
  EntitySuggestionSchema,
  NewEntitySchema,
};
