type EmptyStateProps = {
  title: string;
  description: string | JSX.Element;
  titleSize?: string;
  icon?: JSX.Element;
  button?: JSX.Element;
};

const EmptyState = ({
  title,
  description,
  titleSize,
  icon,
  button,
}: EmptyStateProps) => {
  return (
    <div className="tw-mx-auto tw-flex tw-max-w-xl tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-py-8 tw-text-center">
      <div className="tw-flex tw-flex-col tw-items-center tw-gap-2">
        {icon}
        <div>
          <h4 className={`tw-font-medium tw-text-${titleSize || "lg"}`}>
            {title}
          </h4>
          <p className="tw-text-sm tw-text-secondary">{description}</p>
        </div>
      </div>
      {button}
    </div>
  );
};

export { EmptyState };
