import { z } from "zod";

import { LedgerVersionSchema, ShareTypeSchema } from "./shares";

const CreateSharedViewSchema = z.object({
  shareLedgerVersion: z.string(),
  expiry: z.string(),
  emails: z.array(z.string()),
});

type CreateSharedView = z.infer<typeof CreateSharedViewSchema>;

const AddSharedViewResponseSchema = z.object({
  viewId: z.string(),
  failedEmailInvitations: z.array(
    z.object({
      email: z.string(),
      invitationSent: z.boolean(),
    })
  ),
});

type AddSharedViewResponse = z.infer<typeof AddSharedViewResponseSchema>;

const SharedViewSchema = z.object({
  viewId: z.string(),
  createdAt: z.string(),
  createdBy: z.string(),
  shareLedgerVersion: z.string(),
  expiryDate: z.string(),
  sharedEmails: z.array(
    z.object({
      email: z.string(),
      invitationSent: z.boolean(),
    })
  ),
});

type SharedView = z.infer<typeof SharedViewSchema>;

const SharedViewListSchema = z.array(SharedViewSchema);

const VerifyResponseSchema = z.object({
  isReVerification: z.boolean().nullable(),
  status: z.number(),
});

const VerifySchema = z.object({
  orgNumber: z.string(),
  viewId: z.string(),
  email: z.string(),
});

type Verify = z.infer<typeof VerifySchema>;

const VerifyCodeSchema = VerifySchema.extend({
  code: z.string(),
});

type VerifyCode = z.infer<typeof VerifyCodeSchema>;

const TokenSchema = z.object({
  accessToken: z.string(),
  refreshToken: z.string(),
  expiresAt: z.string(),
});

type Token = z.infer<typeof TokenSchema>;

const VerifyCodeResponseSchema = z.object({
  token: TokenSchema,
});

type VerifyCodeResponse = z.infer<typeof VerifyCodeResponseSchema>;

const ShareholderSchema = z.object({
  holder: z.object({
    id: z.string(),
    refId: z.string(),
    type: z.enum(["Private", "Company"]),
    since: z.string(),
  }),
  entity: z.object({
    id: z.string(),
    refId: z.string(),
    type: z.enum(["Private", "Company"]),
    name: z.string(),
    countryCode: z.string(),
    passportNumber: z.string().nullable().optional(),
    contact: z.object({}),
  }),
  shareTypes: z.record(z.number()),
  totalShares: z.number(),
  totalVotes: z.number(),
});

type Shareholder = z.infer<typeof ShareholderSchema>;

const ShareholdersSchema = z
  .object({
    shareholders: z.array(ShareholderSchema),
    shareTypes: z.array(ShareTypeSchema),
    companyName: z.string(),
    createdAt: z.string(),
    version: LedgerVersionSchema,
    status: z.enum(["Old", "Approved", "Draft"]),
    totalShares: z.number(),
    totalVotes: z.number(),
  })
  .optional();

type Shareholders = z.infer<typeof ShareholdersSchema>;

export {
  AddSharedViewResponseSchema,
  SharedViewListSchema,
  ShareholdersSchema,
  VerifyCodeResponseSchema,
  VerifyResponseSchema,
};
export type {
  AddSharedViewResponse,
  CreateSharedView,
  SharedView,
  Shareholder,
  Shareholders,
  Token,
  Verify,
  VerifyCode,
  VerifyCodeResponse,
};
