import "./index.css";

import { Tab as TwTab } from "@headlessui/react";

import type { ExtractProps } from "../../../types";
import { clsxm } from "../../../utils/tailwind";

type TabProps = {
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  children: string | React.ReactNode;
};

type TabGroupProps = ExtractProps<typeof TwTab.Group>;

const Tab = ({ className, disabled, children, ...props }: TabProps) => (
  <TwTab
    className={clsxm(
      "tw-px-4 tw-pb-1 tw-pt-2 tw-text-secondary tw-outline-none tw-ring-0 focus:tw-ring-0",
      { "tw-text-neutral-400": disabled },
      className
    )}
    disabled={disabled}
    {...props}
  >
    <div className="tw-flex tw-flex-col tw-items-center">
      {children}
      <span className="tw-mt-1.5 tw-block tw-h-0.5 tw-w-5 tw-rounded-sm" />
    </div>
  </TwTab>
);
Tab.Group = (props: TabGroupProps) => <TwTab.Group {...props} />;
Tab.List = ({ className, ...props }: ExtractProps<typeof TwTab.List>) => (
  <TwTab.List className={className} {...props} />
);
Tab.Panels = (props: ExtractProps<typeof TwTab.Panels>) => (
  <TwTab.Panels {...props} />
);
Tab.Panel = (props: ExtractProps<typeof TwTab.Panel>) => (
  <TwTab.Panel {...props} />
);

export { Tab };
export type { TabGroupProps, TabProps };
