import type {
  EntityDto,
  GETEntitiesOrgNumberResponse,
  SavedEntityDto,
} from "@capchapdev/admin-api";
import type {
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useMutation, useQuery } from "@tanstack/react-query";
import { TxRejectedError } from "postchain-client";

import { useBlockchainClient } from "../../context/blockchain";
import { getUserFromStorage } from "../../context/session";
import type {
  Entity,
  EntityCore,
  EntitySuggestion,
  EntitySuggestionOptions,
  NewEntity,
} from "../../types/models/entities";
import {
  EntitiesSchema,
  EntityCoreSchema,
  EntitySchema,
  EntitySuggestionSchema,
  NewEntitySchema,
} from "../../types/models/entities";
import * as monitoring from "../../utils/monitoring";
import type { IRequestError } from "..";
import { BlockchainClient } from "../blockchain/client";
import useClient, { NoContentSchema, URL } from "./client";
import { RawTxResponse, RawTxResponseSchema } from "./users";

const getEntities = async (orgNumber: string) => {
  const client = useClient({ hasAuth: true });

  const response = await client<GETEntitiesOrgNumberResponse>(
    `${URL.ADMIN}/Entities/${orgNumber}`
  );

  const result = EntitiesSchema.safeParse(response);

  // This is a hack to make sure that we don't have any entities without a name,
  // which caused the app to crash. As a temporary hack, we will just set the
  // name to a placeholder value and only report to Sentry if the patched
  // response fails to parse as well.
  if (!result.success) {
    const patchedResponse = response.map((entity) => ({
      ...entity,
      name: entity.name ?? "<Entity missing name>",
    }));

    const resultPatched = EntitiesSchema.safeParse(patchedResponse);

    if (!resultPatched.success) {
      // Failed even after patching. Include the unpatched response in the error
      monitoring.captureException(result.error, {
        contexts: { data: { response }, result },
      });

      // Even if the patched response fails, it's still better than not having
      // it patched, because some renders will fail otherwise.
      return patchedResponse as Entity[];
    }

    return resultPatched.data;
  }

  return result.data;
};

const getRepresentativeEntity = async (orgNumber: string, entityId: string) => {
  const client = useClient({ hasAuth: true });

  const response = await client<EntityCore>(
    `${URL.ADMIN}/Entities/Representative/${orgNumber}/${entityId}`
  );

  const result = EntityCoreSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response;
  }

  return result.data;
};

const getMyEntity = async (orgNumber: string) => {
  const client = useClient({ hasAuth: true });

  const response = await client<NewEntity>(
    `${URL.ADMIN}/Entities/Me/${orgNumber}`
  );

  const result = NewEntitySchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response;
  }

  return result.data;
};

const updateContactDetails = async (
  orgNumber: string,
  data: { email: string; phone: string }
) => {
  const client = useClient({ hasAuth: true });

  const response = await client<undefined>(
    `${URL.ADMIN}/Entities/Me/Contact/${orgNumber}`,
    { body: data, method: "PATCH" }
  );

  const result = NoContentSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response as unknown as undefined;
  }

  return result.data;
};

const getEntitySuggestion = async (options: EntitySuggestionOptions) => {
  const client = useClient({ hasAuth: true });

  const response = await client<EntityDto>(
    `${URL.ADMIN}/Entities/Suggest?${new URLSearchParams(options).toString()}`,
    {},
    [404]
  );

  const result = EntitySuggestionSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response as EntitySuggestion;
  }

  return result.data || null;
};

const postEntity = async (orgNumber: string, entity: NewEntity) => {
  const client = useClient({ hasAuth: true });

  const response = await client<SavedEntityDto>(
    `${URL.ADMIN}/Entities/${orgNumber}`,
    { body: entity }
  );

  const result = EntitySchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response as Entity;
  }

  return result.data;
};

const putEntity = async (orgNumber: string, id: string, entity: NewEntity) => {
  const client = useClient({ hasAuth: true });

  const response = await client<undefined>(
    `${URL.ADMIN}/Entities/${orgNumber}/${id}`,
    { body: entity, method: "PUT" }
  );

  const result = NoContentSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response as unknown as undefined;
  }

  return result.data;
};

const deleteEntityRelationship = async (
  blockchainClient: BlockchainClient,
  orgNumber: string,
  parentId: string,
  id: string
) => {
  const client = useClient({ hasAuth: true });

  const response = await client<RawTxResponse>(
    `${URL.ADMIN}/Transaction/RemoveRelationship/${orgNumber}/${parentId}/${id}`,
    { method: "GET" }
  );

  const result = RawTxResponseSchema.optional().safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return blockchainClient.sendTransaction(response.rawTx);
  }
  if (!result.data) {
    return null;
  }

  return blockchainClient.sendTransaction(result.data.rawTx);
};

const postEntityRelationship = async (
  blockchainClient: BlockchainClient,
  orgNumber: string,
  id: string,
  entityIds: string[]
) => {
  const client = useClient({ hasAuth: true });

  const response = await client<RawTxResponse>(
    `${URL.ADMIN}/Transaction/AddRelationships/${orgNumber}/${id}`,
    { method: "PUT", body: { ids: entityIds } }
  );

  const result = RawTxResponseSchema.optional().safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return blockchainClient.sendTransaction(response.rawTx);
  }
  if (!result.data) {
    return null;
  }

  return blockchainClient.sendTransaction(result.data.rawTx);
};

const useEntitiesQuery = (
  orgNumber: string | undefined,
  options: UseQueryOptions<
    Entity[],
    IRequestError,
    Entity[],
    [string, string, string]
  > = {}
): UseQueryResult<Entity[], IRequestError> =>
  useQuery<Entity[], IRequestError, Entity[], [string, string, string]>(
    ["entities", orgNumber!, getUserFromStorage()?.refId ?? ""],
    () => getEntities(orgNumber!),
    {
      staleTime: Infinity,
      enabled:
        options?.enabled !== undefined
          ? options.enabled
          : !!orgNumber && getUserFromStorage()?.refId !== undefined,
      ...options,
    }
  );

const useRepresentativeEntityQuery = (
  orgNumber: string | undefined,
  id: string | undefined
): UseQueryResult<EntityCore, IRequestError> =>
  useQuery<EntityCore, IRequestError, EntityCore, [string, string, string]>(
    ["representativeEntity", orgNumber!, id!],
    () => getRepresentativeEntity(orgNumber!, id!),
    { enabled: !!orgNumber && !!id }
  );

const useMyEntityQuery = (
  orgNumber: string | undefined
): UseQueryResult<NewEntity, IRequestError> =>
  useQuery<NewEntity, IRequestError, NewEntity, [string, string]>(
    ["myEntity", orgNumber!],
    () => getMyEntity(orgNumber!),
    { enabled: !!orgNumber }
  );

const useUpdateMyContactDetailsMutation = (
  orgNumber: string,
  options?: UseMutationOptions<
    undefined,
    IRequestError,
    { phone: string; email: string }
  >
) =>
  useMutation<undefined, IRequestError, { phone: string; email: string }>(
    (data) => updateContactDetails(orgNumber, data),
    options
  );

const useEntitySuggestionQuery = (
  options: EntitySuggestionOptions,
  queryOptions?: UseQueryOptions<
    EntitySuggestion,
    IRequestError,
    EntitySuggestion
  >
) =>
  useQuery<EntitySuggestion, IRequestError>(
    ["entities/suggest", options],
    () => getEntitySuggestion(options),
    { cacheTime: 0, enabled: false, ...queryOptions }
  );

const useAddEntityMutation = (
  orgNumber: string,
  options?: UseMutationOptions<Entity, IRequestError, NewEntity>
) =>
  useMutation<Entity, IRequestError, NewEntity>(
    (data) => postEntity(orgNumber, data),
    options
  );

const useUpdateEntityMutation = (
  orgNumber: string,
  id: string,
  options?: UseMutationOptions<Entity, IRequestError, NewEntity>
) =>
  // @ts-expect-error - TODO: Figure out differences between BE response and FE type. putEntity returns undefined, but useMutation claims it returns TEntity
  useMutation<Entity, IRequestError, NewEntity>(
    (data) => putEntity(orgNumber, id, data),
    options
  );

const useDeleteEntityRelationshipMutation = (
  orgNumber: string,
  parentId: string,
  id: string,
  options?: UseMutationOptions<unknown, IRequestError | TxRejectedError>
) => {
  const blockchainClient = useBlockchainClient();

  return useMutation<unknown, IRequestError>(
    () => deleteEntityRelationship(blockchainClient, orgNumber, parentId, id),
    options
  );
};

const useAddEntityRelationshipMutation = (
  orgNumber: string,
  id: string,
  options?: UseMutationOptions<
    unknown,
    IRequestError | TxRejectedError,
    string[]
  >
) => {
  const blockchainClient = useBlockchainClient();

  return useMutation<unknown, IRequestError, string[]>(
    (data) => postEntityRelationship(blockchainClient, orgNumber, id, data),
    options
  );
};

const initEmailVerification = async (orgNumber: string) => {
  const client = useClient({ hasAuth: true });

  const response = await client<undefined>(
    `${URL.ADMIN}/Entities/${orgNumber}/VerifyEmail`,
    { method: "POST" }
  );

  const result = NoContentSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });
  }
};

const useInitEmailVerificationMutation = (
  orgNumber: string,
  options?: UseMutationOptions<void, IRequestError, void>
) =>
  useMutation<void, IRequestError, void>(
    () => initEmailVerification(orgNumber),
    options
  );

const verifyEmail = async (orgNumber: string, code: string, id: string) => {
  const client = useClient({ hasAuth: true });

  const response = await client<string>(
    `${URL.ADMIN}/Entities/${orgNumber}/EmailVerification/${code}/${id}`,
    { method: "POST" },
    [400]
  );

  const result = NoContentSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { result },
    });
  }

  return response;
};

type VerifyEmailMutationRequestVars = {
  orgNumber: string;
  code: string;
  id: string;
};

const useVerifyEmailMutation = (
  options?: UseMutationOptions<
    string,
    IRequestError,
    VerifyEmailMutationRequestVars
  >
) =>
  useMutation<string, IRequestError, VerifyEmailMutationRequestVars>(
    ({ orgNumber, code, id }) => verifyEmail(orgNumber, code, id),
    options
  );

export {
  useAddEntityMutation,
  useAddEntityRelationshipMutation,
  useDeleteEntityRelationshipMutation,
  useEntitiesQuery,
  useEntitySuggestionQuery,
  useInitEmailVerificationMutation,
  useMyEntityQuery,
  useRepresentativeEntityQuery,
  useUpdateEntityMutation,
  useUpdateMyContactDetailsMutation,
  useVerifyEmailMutation,
};
