import { useTranslation } from "react-i18next";

import { useCancelProposal } from "../../../../../../api/rest/events";
import { Button } from "../../../../../../components/design-system/Button";
import { Dialog } from "../../../../../../components/design-system/Dialog";
import { FormErrorList } from "../../../../../../components/design-system/FormGroup";
import { CompanyInformation } from "../../../../../../types/models/administration";
import { CompanyInvolvement } from "../../../../../../types/models/company";

type CancelDialogProps = {
  onSuccess: () => void;
  onClose: () => void;
  currentCompany: CompanyInvolvement | CompanyInformation;
};

const CancelDialog = ({
  currentCompany,
  onClose,
  onSuccess,
}: CancelDialogProps) => {
  const i18n = useTranslation();
  const cancelProposalMutation = useCancelProposal(currentCompany.orgNumber, {
    onSuccess,
  });

  return (
    <Dialog
      isOpen
      title={i18n.t("approvalPolicy.cancel.confirm.title")}
      onClose={onClose}
      isLoading={cancelProposalMutation.isLoading}
      actions={
        <>
          <Button disabled={cancelProposalMutation.isLoading} onClick={onClose}>
            {i18n.t("label.cancel")}
          </Button>
          <Button
            type="submit"
            variant="solid"
            color="primary"
            isLoading={cancelProposalMutation.isLoading}
            onClick={() => {
              cancelProposalMutation.mutate();
            }}
          >
            {i18n.t("approvalPolicy.cancel.confirm.submit")}
          </Button>
        </>
      }
    >
      <>
        <div>{i18n.t("approvalPolicy.cancel.confirm.description")}</div>
        {cancelProposalMutation.error && (
          <FormErrorList error={cancelProposalMutation.error} />
        )}
      </>
    </Dialog>
  );
};

export { CancelDialog };
