import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

type BreadcrumbProps = {
  links: { url: string; name: string }[];
  current: string;
};

const Breadcrumb = ({ links, current }: BreadcrumbProps) => {
  const mostRecentLink = links[links.length - 1]!;
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });

  return (
    <div className="tw-flex tw-items-center tw-gap-2 tw-py-1">
      {isTabletOrMobileDevice ? (
        <>
          <CaretLeft />
          <Link to={mostRecentLink.url}>
            <p className="tw-text-sm ">{mostRecentLink.name}</p>
          </Link>
        </>
      ) : (
        <>
          {links.map(({ url, name }) => (
            <>
              <Link to={url}>
                <p className="tw-text-sm ">{name}</p>
              </Link>
              <CaretRight />
            </>
          ))}
          <p className="tw-text-sm ">{current}</p>
        </>
      )}
    </div>
  );
};

export { Breadcrumb };
