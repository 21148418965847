import { ResponsivePie } from "@nivo/pie";
import { useTranslation } from "react-i18next";

import { formatNumber } from "../../utils/format";
import { ShareHolderWithDilution } from "../CompanyShares/ShareHolders.utils";

type ShareClassesProps = {
  shareholders: ShareHolderWithDilution[];
  totalShares: number;
};

const colorPalette = ["#563F6F", "#A084BE", "#D7C5EB"];

const TotalShares = ({ shareholders, totalShares }: ShareClassesProps) => {
  const i18n = useTranslation();
  const data = shareholders.map((s) => ({
    id: s.entity.name,
    value: s.totalShares,
  }));

  return (
    <div className="tw-flex tw-justify-center tw-gap-4 tw-whitespace-nowrap tw-rounded-lg tw-border tw-border-neutral-200 tw-p-4">
      <div style={{ height: "200px", width: "200px", position: "relative" }}>
        <ResponsivePie
          data={data}
          margin={{ left: 10, right: 10, top: 10, bottom: 10 }}
          innerRadius={0.75}
          cornerRadius={0}
          colors={colorPalette}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]],
          }}
          tooltip={({ datum }) => (
            <div className="tw-flex tw-items-center tw-gap-2 tw-rounded tw-border tw-bg-white tw-p-2">
              <div
                className="tw-h-4 tw-w-4 tw-rounded-sm"
                style={{ backgroundColor: datum.color }}
              />
              <span className="tw-text-sm">{datum.label}:</span>
              <span className="tw-text-sm tw-font-medium">
                {formatNumber(datum.value)}
              </span>
            </div>
          )}
        />
        {/* Add a centered label */}
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          <p className="tw-text-sm tw-text-secondary">
            {i18n.t("externalView.shareTypes.total")}
          </p>
          <p className="tw-text-sm tw-font-medium tw-text-primary">
            {formatNumber(totalShares)}
          </p>
        </div>
      </div>
    </div>
  );
};

export { TotalShares };
