import { SpinnerIcon } from "../icons";

const Loading = (props: React.ComponentProps<typeof SpinnerIcon>) => (
  <div
    className="tw-flex tw-shrink-0 tw-items-center tw-justify-center tw-py-16"
    data-testid="loading-spinner"
  >
    <SpinnerIcon {...props} />
  </div>
);

export { Loading };
