import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { useApprovalInfoQuery } from "../../../../../api/blockchain/company";
import { useUsersQuery } from "../../../../../api/blockchain/users";
import { useEntitiesQuery } from "../../../../../api/rest/entities";
import { EntityItem } from "../../../../../components/design-system/EntityItem";
import { Loading } from "../../../../../components/design-system/Loading";
import { Table } from "../../../../../components/design-system/Table";
import { TooltipV2 } from "../../../../../components/design-system/Tooltip/TooltipV2";
import { AddUser } from "../../../../../components/Users/Add";
import { DeleteUser } from "../../../../../components/Users/Delete";
import { EditUser } from "../../../../../components/Users/Edit";
import { useSession } from "../../../../../context/session";
import { CompanyInformation } from "../../../../../types/models/administration";
import { CompanyInvolvement } from "../../../../../types/models/company";
import { Role } from "../../../../../types/models/users";
import { getApprovers } from "../../../../../utils/approvalUtils";
import { hasRequiredPermission } from "../../../../../utils/permissions";
import { supportUserId } from "../../../../../utils/user";

const UsersTable = ({
  currentCompany,
}: {
  currentCompany: CompanyInformation | CompanyInvolvement;
}) => {
  const i18n = useTranslation();
  const { user } = useSession();
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const [sortBy, setSortBy] = useState("name-asc");
  const usersQuery = useUsersQuery(currentCompany?.orgNumber || "");
  const users = usersQuery.data || [];
  const approvalInfo = useApprovalInfoQuery(
    currentCompany?.orgNumber || "",
    ""
  );
  const entitiesQuery = useEntitiesQuery(currentCompany?.orgNumber);
  const entities = entitiesQuery.data || [];
  const approvers =
    approvalInfo.data &&
    approvalInfo.data.rule !== "None" &&
    (approvalInfo.data.approvedBy || approvalInfo.data.pendingApprovalBy) &&
    getApprovers(approvalInfo.data, entities, users, user?.id || "");

  if (
    usersQuery.isLoading ||
    approvalInfo.isLoading ||
    entitiesQuery.isLoading
  ) {
    return <Loading />;
  }

  const roleLabels: Record<Role, string> = {
    Viewer: i18n.t("role.viewer"),
    Editor: i18n.t("role.editor"),
    Administrator: i18n.t("role.administrator"),
    BoardMember: i18n.t("role.boardMember"),
  };

  const userRows = users
    .filter((i) => i.user.id !== supportUserId)
    .map((u) => {
      const entity = entities.find(
        (e) => e.id === u.user.id || e.refId === u.user.refId
      );
      const isApprover = (approvers || [])?.some(
        (approver) => approver.key === u.user.id
      );
      return { entity, user: u, isApprover };
    });

  const sortedUserRows = userRows.sort((a, b) => {
    const [sortByColumn, sortOrder] = sortBy.split("-");
    if (sortByColumn === "name") {
      return sortOrder === "asc"
        ? (a.entity?.name || "").localeCompare(b.entity?.name || "")
        : (b.entity?.name || "").localeCompare(a.entity?.name || "");
    }
    if (sortByColumn === "role") {
      return sortOrder === "asc"
        ? roleLabels[a.user.role].localeCompare(roleLabels[b.user.role])
        : roleLabels[b.user.role].localeCompare(roleLabels[a.user.role]);
    }
    return 0;
  });

  const isAdmin = hasRequiredPermission("Administrator", currentCompany, user);

  return (
    <Table
      columns={[
        {
          name: "name",
          title: i18n.t("label.name"),
          className: "tw-px-0 md:tw-px-6",
        },
        {
          name: "role",
          title: i18n.t("label.role"),
          className: "max-md:tw-hidden",
        },
        {
          name: "",
          title: "",
        },
      ]}
      sortBy={sortBy}
      setSortBy={setSortBy}
    >
      {sortedUserRows.map((row) => (
        <tr
          className="tw-border-t-[1px] tw-border-neutral-200"
          key={row.user.user.id}
        >
          <td className="tw-flex tw-flex-col tw-px-0 tw-py-4 md:tw-flex-row md:tw-px-6">
            {row.entity && (
              <EntityItem
                value={row.entity}
                showId
                hasFlag={false}
                displayIcon={false}
                {...(isTabletOrMobileDevice && {
                  customDescription: roleLabels[row.user.role],
                })}
              />
            )}
          </td>
          <td className="tw-px-6 tw-py-4 max-md:tw-hidden">
            <p className="tw-text-sm">{roleLabels[row.user.role]}</p>
          </td>
          <td className="tw-px-6 tw-py-4 tw-text-sm">
            {row.user.role !== "BoardMember" && isAdmin && (
              <div className="tw-flex tw-flex-row tw-justify-end">
                <EditUser
                  currentCompany={currentCompany}
                  value={{ ...row.user }}
                  onSuccess={() => usersQuery.refetch()}
                  isApprover={row.isApprover}
                />
                <TooltipV2
                  content={
                    row.isApprover
                      ? i18n.t("label.users.disableHover.tooltip")
                      : null
                  }
                  theme="light"
                  placement="bottom-start"
                  breakText
                >
                  <DeleteUser
                    currentCompany={currentCompany}
                    user={row.user.user}
                    onSuccess={() => usersQuery.refetch()}
                    disabled={row.isApprover}
                  />
                </TooltipV2>
              </div>
            )}
          </td>
        </tr>
      ))}
    </Table>
  );
};

const UserSection = ({
  currentCompany,
}: {
  currentCompany: CompanyInformation | CompanyInvolvement;
}) => {
  const i18n = useTranslation();
  const { user } = useSession();
  const usersQuery = useUsersQuery(currentCompany?.orgNumber || "");
  const canEdit = hasRequiredPermission("Administrator", currentCompany, user);

  if (usersQuery.isLoading) {
    return <Loading />;
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <div className="tw-flex tw-flex-col tw-justify-between tw-gap-1 md:tw-flex-row">
        <div>
          <h3 className="tw-text-xl tw-font-medium">
            {i18n.t("settings.users.heading")}
          </h3>
          <p className="tw-text-sm tw-text-secondary">
            {i18n.t("settings.users.description")}
          </p>
        </div>
        <div>
          {canEdit && (
            <AddUser
              currentCompany={currentCompany}
              onSuccess={() => usersQuery.refetch()}
            />
          )}
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-6">
        <UsersTable currentCompany={currentCompany} />
      </div>
    </div>
  );
};

export { UserSection };
