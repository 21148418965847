import * as XLSX from "xlsx";

type SpreadsheetData = { [key: string]: string | number }[];
const columnWidthMultiplier = 8;
const dataStartRow = 2;

function incrementRange(range: string) {
  const parts = range.split(":");

  // Extract the column and row from the ending part
  const match = parts[1]!.match(/^([A-Z]+)(\d+)$/);
  if (!match) {
    throw new Error("Invalid range format");
  }

  const column = match[1]; // e.g., "A"
  const row = parseInt(match[2]!, 10); // e.g., 4

  // Increment the row
  const newRow = row + 1;

  return `${parts[0]}:${column}${newRow}`;
}

function createWorksheet(data: SpreadsheetData, columnsWithTotals?: string[]) {
  const keys = data[0] ? Object.keys(data[0]) : [];

  // Create percentage calculation columns
  const transformedData = data.map((row, i) => {
    const percentageColumns = columnsWithTotals
      ? columnsWithTotals.reduce((prev, c) => {
          const index = keys.indexOf(c);
          const char = String.fromCharCode((index % 26) + 65); // Index to letter (i.e. 0 -> A, 1 -> B etc.)
          const valueCell = `${char}${i + 2}`;
          const totalCell = `${char}${data.length + 2}`;
          return {
            ...prev,
            [`% ${c}`]: { t: "n", f: `${valueCell}/${totalCell}`, z: "0.00%" },
          };
        }, {} as Record<string, { t: string; f: string; z: string }>)
      : [];
    return { ...row, ...percentageColumns };
  });

  const ws = XLSX.utils.json_to_sheet(transformedData);

  // Generate total formulas
  if (columnsWithTotals && columnsWithTotals.length > 0) {
    columnsWithTotals.forEach((column) => {
      const index = keys.indexOf(column);
      const char = String.fromCharCode((index % 26) + 65); // Index to letter (i.e. 0 -> A, 1 -> B etc.)
      const endRow = data.length + 1; // Add 1 for header
      const sumFormulaCell = `${char}${endRow + 1}`;
      const formula = `SUM(${char}${dataStartRow}:${char}${endRow})`;
      ws[sumFormulaCell] = { t: "n", f: formula };
    });
    ws["!ref"] = incrementRange(ws["!ref"]!);
  }

  // Adjust column widths
  const columnWidths = keys.map((key) =>
    Math.max(
      ...[...data.map((row) => row[key]?.toString().length || 0), key.length]
    )
  );
  ws["!cols"] = columnWidths.map((w) => ({
    wpx: Math.max(w * columnWidthMultiplier, 50),
  }));
  return ws;
}

function exportToExcel(
  data: SpreadsheetData,
  columnsWithTotals: string[],
  fileName: string,
  sheetName: string
) {
  const ws = createWorksheet(data, columnsWithTotals);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  XLSX.writeFile(wb, `${fileName}.xlsx`);
}

export { createWorksheet, exportToExcel };
export type { SpreadsheetData };
