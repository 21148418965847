import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  useAddSharedViewMutation,
  useEditSharedViewMutation,
} from "../../api/rest/views";
import { Alert } from "../../components/design-system/Alert";
import { Button } from "../../components/design-system/Button";
import { Dialog } from "../../components/design-system/Dialog";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../components/design-system/FormGroup";
import { Input } from "../../components/design-system/Input";
import { TagsInput } from "../../components/design-system/TagsInput";
import { SelectVersion } from "../../components/SelectVersion";
import { dateToIsoString } from "../../utils/date";
import { emailRegex } from "../../utils/validation";
import { useLedgerVersions } from "../CompanyShares/useLedgerVersions";

type CreateViewDialogProps = {
  orgNumber: string;
  onClose: () => void;
  onSuccess: () => void;
  defaultValues?: {
    viewId?: string;
    shareLedgerVersion: string;
    expiryDate?: string;
    sharedEmails?: { email: string; invitationSent: boolean }[];
  };
};

const addDays = (date: Date, days: number) => {
  date.setDate(date.getDate() + days);
  return date;
};

const formId = "create-view-form";

const ViewDialog = ({
  orgNumber,
  onClose,
  onSuccess,
  defaultValues,
}: CreateViewDialogProps) => {
  const i18n = useTranslation();
  const ledgerVersions = useLedgerVersions(orgNumber);
  const [invalidEmails, setInvalidEmails] = useState<string[]>(
    (defaultValues?.sharedEmails || [])
      .filter((i) => !i.invitationSent)
      .map((i) => i.email)
  );
  const [viewId, setViewId] = useState<string | undefined>(
    defaultValues?.viewId
  );

  const editMutation = useEditSharedViewMutation(orgNumber, viewId, {
    onSuccess: (data) => {
      if (data.failedEmailInvitations.length > 0) {
        setInvalidEmails(data.failedEmailInvitations.map((i) => i.email));
      } else {
        onSuccess();
      }
    },
  });
  const addMutation = useAddSharedViewMutation(orgNumber, {
    onSuccess: (data) => {
      if (data.failedEmailInvitations.length > 0) {
        setInvalidEmails(data.failedEmailInvitations.map((i) => i.email));
        setViewId(data.viewId);
      } else {
        onSuccess();
      }
    },
  });
  const isEdit = !!viewId;
  const mutation = isEdit ? editMutation : addMutation;

  const endOfDayTimestamp = (date: Date) => {
    date.setHours(23, 59, 59, 999);
    return date.toISOString();
  };

  const { control, handleSubmit, setError, clearErrors } = useForm({
    mode: "onSubmit",
    defaultValues: {
      shareLedgerVersion: defaultValues?.shareLedgerVersion || "",
      expiry: defaultValues?.expiryDate
        ? endOfDayTimestamp(new Date(defaultValues.expiryDate))
        : "",
      emails: defaultValues?.sharedEmails
        ? defaultValues.sharedEmails.map(({ email }) => email)
        : [],
    },
  });

  return (
    <Dialog
      title={i18n.t("views.create.title")}
      isOpen
      onClose={onClose}
      canClose={!mutation.isLoading}
      actions={
        <Button
          className="tw-flex-1"
          variant="solid"
          color="primary"
          type="submit"
          form={formId}
          isLoading={mutation.isLoading}
        >
          {i18n.t(isEdit ? "views.edit.submit" : "views.create.submit")}
        </Button>
      }
    >
      <p className="tw-pb-4 tw-text-sm tw-text-secondary">
        {i18n.t("views.create.description")}
      </p>
      <form
        className="tw-space-y-4"
        onSubmit={(event) =>
          handleSubmit((data) => mutation.mutate(data))(event)
        }
        id={formId}
      >
        <FormGroup>
          <FormLabel htmlFor="shareLedgerVersion">
            {i18n.t("views.create.version")}
          </FormLabel>
          <Controller
            control={control}
            name="shareLedgerVersion"
            render={({ field: { onChange, value }, fieldState }) => (
              <>
                <SelectVersion
                  onChange={(v) => onChange(v?.formatedValue)}
                  availableVersions={ledgerVersions}
                  selectedVersion={ledgerVersions.find(
                    (x) => x.formatedValue === value
                  )}
                  menuPosition="fixed"
                  isDisabled={isEdit}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="expiry">
            {i18n.t("views.create.expiry")}
          </FormLabel>
          <Controller
            control={control}
            render={({ field: { ref, name, onChange, value }, fieldState }) => (
              <>
                <Input
                  id="expiry"
                  value={value.split("T")[0]}
                  ref={ref}
                  name={name}
                  onChange={(e) =>
                    onChange(endOfDayTimestamp(new Date(e.target.value)))
                  }
                  type="date"
                  className="tw-w-full"
                  min={dateToIsoString(addDays(new Date(), 1))}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            name="expiry"
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>{i18n.t("views.create.emails")}</FormLabel>
          <Controller
            control={control}
            render={({ field: { name, onChange, value }, fieldState }) => (
              <>
                <TagsInput
                  name={name}
                  value={value}
                  placeholder={i18n.t("views.create.emails.placeholder")}
                  onChange={onChange}
                  validate={(tag, existingTags) => {
                    if (existingTags.includes(tag)) {
                      setError("emails", {
                        type: "manual",
                        message: i18n.t("error.validation.duplicate"),
                      });
                      return false;
                    }
                    if (!emailRegex.test(tag)) {
                      setError("emails", {
                        type: "manual",
                        message: i18n.t("error.validation.format"),
                      });
                      return false;
                    }
                    clearErrors("emails");
                    return true;
                  }}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            name="emails"
            rules={{
              validate: (data) => {
                if (data.some((i) => !emailRegex.test(i))) {
                  return i18n.t("error.validation.format");
                }
                return true;
              },
            }}
          />
        </FormGroup>
        {invalidEmails.length > 0 && (
          <Alert type="error">
            {i18n.t("error.validation.emails.invites", {
              emails: invalidEmails.join(","),
            })}
          </Alert>
        )}
        {mutation.error && <FormErrorList error={mutation.error} />}
      </form>
    </Dialog>
  );
};

export { ViewDialog };
