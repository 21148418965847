import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button } from "../../components/design-system/Button";
import { PublicPageLayout } from "../../components/PublicPageLayout";

const AccessDenied = () => {
  const i18n = useTranslation();

  return (
    <PublicPageLayout>
      <div className="tw-w-full tw-text-center">
        <div className="tw-mx-auto tw-flex tw-max-w-3xl tw-flex-col tw-gap-4">
          <h1>{i18n.t("externalView.accessDenied.title")}</h1>
          <p className="tw-text-secondary">
            {i18n.t("externalView.accessDenied.description")}
          </p>
          <Link to="https://www.kvanta.com">
            <Button>{i18n.t("externalView.accessDenied.link")}</Button>
          </Link>
        </div>
      </div>
    </PublicPageLayout>
  );
};

export { AccessDenied };
