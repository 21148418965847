import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useUploadEventAttachmentsMutation } from "../../api/rest/attachments";
import { useSession } from "../../context/session";
import { Alert } from "../design-system/Alert";
import { Attachment } from "../design-system/Attachment";
import { Button } from "../design-system/Button";
import { Dialog } from "../design-system/Dialog";

type EventAttachmentsProps = {
  orgNumber: string;
  eventId: string;
  onClose: () => void;
};

const EventAttachments = ({
  orgNumber,
  eventId,
  onClose,
}: EventAttachmentsProps) => {
  const i18n = useTranslation();
  const { user } = useSession();
  const [files, setFiles] = useState<File[]>([]);

  const uploadMutation = useUploadEventAttachmentsMutation(orgNumber, {
    onSuccess: onClose,
  });

  return (
    <Dialog
      isOpen
      title={i18n.t("event.attachments.dialog.title")}
      onClose={onClose}
      isLoading={uploadMutation.isLoading}
      actions={
        <>
          <Button disabled={uploadMutation.isLoading} onClick={onClose}>
            {i18n.t("label.cancel")}
          </Button>
          <Button
            type="submit"
            variant="solid"
            color="primary"
            isLoading={uploadMutation.isLoading}
            disabled={files.length === 0}
            onClick={() => {
              uploadMutation.mutate({ files, eventId, userId: user!.id });
            }}
          >
            {i18n.t("label.save")}
          </Button>
        </>
      }
    >
      <div className="tw-pt-4">
        <Attachment files={files} setFiles={setFiles} />
        {uploadMutation.error && (
          <Alert type="error" className="tw-mt-2">
            {i18n.t("events.attachments.error")}
          </Alert>
        )}
      </div>
    </Dialog>
  );
};

export { EventAttachments };
