import { WarningCircle } from "@phosphor-icons/react/dist/ssr";
import { useTranslation } from "react-i18next";

import { useParentEventsQuery } from "../../../../api/blockchain/events";
import { useStartApprovalMutation } from "../../../../api/rest/company";
import { useSession } from "../../../../context/session";
import { CompanyInformation } from "../../../../types/models/administration";
import { CompanyInvolvement } from "../../../../types/models/company";
import { EntitiesMap } from "../../../../types/models/entities";
import { LedgerVersion } from "../../../../types/models/shares";
import { Badge } from "../../../design-system/Badge";
import { Button } from "../../../design-system/Button";
import { Dialog } from "../../../design-system/Dialog";
import { TooltipV2 } from "../../../design-system/Tooltip/TooltipV2";
import { useApproversStatus } from "../../../History/History.utils";
import { useShouldShowApproveButtons } from "../../EventsWizard.utils";

type PublishConfirmationProps = {
  onSuccess: () => void;
  onClose: () => void;
  currentCompany: CompanyInvolvement | CompanyInformation;
  version?: LedgerVersion;
  entitiesMap: EntitiesMap;
};

const PublishConfirmation = ({
  currentCompany,
  version,
  entitiesMap,
  onClose,
  onSuccess,
}: PublishConfirmationProps) => {
  const i18n = useTranslation();
  const { user } = useSession();

  const eventsQuery = useParentEventsQuery({
    orgNumber: currentCompany.orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  });
  const { pendingApprovalBy } = useApproversStatus(
    currentCompany.orgNumber,
    version,
    entitiesMap
  );
  const otherApprovers = pendingApprovalBy.filter((x) => x.id !== user?.id);
  const { canApprove } = useShouldShowApproveButtons(currentCompany, version);
  const startLedgerApprovalMutation = useStartApprovalMutation(
    currentCompany.orgNumber,
    version,
    canApprove,
    {
      onSuccess: () => {
        eventsQuery.refetch();
        if (onSuccess) {
          onSuccess();
        }
      },
    }
  );
  const isLoading =
    startLedgerApprovalMutation.isLoading || eventsQuery.isLoading;

  return (
    <Dialog
      isOpen
      title={i18n.t("eventsWizard.publishConfirmation.title")}
      onClose={onClose}
      actions={
        <>
          <Button disabled={isLoading} onClick={onClose}>
            {i18n.t("eventsWizard.publishConfirmation.cancel")}
          </Button>
          <Button
            type="submit"
            variant="solid"
            color="primary"
            isLoading={isLoading}
            onClick={() => {
              startLedgerApprovalMutation.mutate();
            }}
          >
            {i18n.t("eventsWizard.publish")}
          </Button>
        </>
      }
    >
      <div className="tw-flex tw-flex-col tw-gap-4">
        <p>{i18n.t("eventsWizard.publishConfirmation.description")}</p>
        {otherApprovers.length > 0 && (
          <div className="tw-flex tw-flex-col tw-gap-2">
            <p>{i18n.t("eventsWizard.publishConfirmation.approvers")}</p>
            <div className="tw-flex tw-flex-wrap tw-gap-2">
              {otherApprovers.map((p) => (
                <TooltipV2
                  key={p.name}
                  content={
                    !p.contact.email
                      ? i18n.t(
                          "eventsWizard.publishConfirmation.approver.noEmail"
                        )
                      : undefined
                  }
                >
                  <Badge color="blue" className="tw-flex tw-gap-2">
                    {p.name} {!p.contact.email && <WarningCircle size={15} />}
                  </Badge>
                </TooltipV2>
              ))}
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export { PublishConfirmation };
