import type { EntityType } from "@capchapdev/admin-api";
import { MagicWand } from "@phosphor-icons/react";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type { IRequestError } from "../../../api";
import {
  useEntitiesQuery,
  useEntitySuggestionQuery,
} from "../../../api/rest/entities";
import { useCurrentCompany } from "../../../context/account";
import { useSession } from "../../../context/session";
import type { Ti18nKey } from "../../../i18n";
import type { NewEntity } from "../../../types/models/entities";
import { hasRequiredPermission } from "../../../utils/permissions";
import { safeAssertNever } from "../../../utils/type";
import {
  emailRegex,
  validateSwedishPersonalNumber,
} from "../../../utils/validation";
import { Button } from "../../design-system/Button";
import { EntityItem } from "../../design-system/EntityItem";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormHint,
  FormLabel,
  FormSection,
} from "../../design-system/FormGroup";
import { Input } from "../../design-system/Input";
import { List } from "../../design-system/List/List";
import {
  isPossiblePhoneNumber,
  PhoneInput,
} from "../../design-system/PhoneInput";
import { SelectCountry } from "../../SelectCountry";
import { SelectEntityType } from "../../SelectEntityType";
import { AddRepresentatives } from "../AddRepresentatives";
import { AttachRepresentatives } from "../AttachRepresentatives";
import { EditEntity } from "../Edit";
import { RemoveRepresentative } from "../RemoveRepresentative";
import { canAutoFill, shouldShowRegion } from "./CreateEntityForm.utils";

const Roles: { [key: string]: Ti18nKey } = {
  BoardMember: "entity.represenatatives.role.boardMember",
  Ceo: "entity.represenatatives.role.ceo",
};

type EntityTypeOptions = "PrivateWithSSN" | "PrivateWithPassport" | "Company";
type EditEntityModel = Omit<NewEntity, "type"> & {
  type: EntityTypeOptions;
};

export function isSSN(refId: string) {
  return !validateSwedishPersonalNumber(refId ?? "") || !refId;
}

// eslint-disable-next-line consistent-return
function getTypeFromDto(dto: NewEntity): EntityTypeOptions {
  switch (dto.type) {
    case "Private":
      return !validateSwedishPersonalNumber(dto.refId ?? "") || !dto.refId
        ? "PrivateWithSSN"
        : "PrivateWithPassport";
    case "Company":
      return "Company";
    default:
      safeAssertNever(dto.type, { contexts: { args: { dto } } });
  }
}

// eslint-disable-next-line consistent-return
function getTypeForDto(dto: EditEntityModel): EntityType {
  switch (dto.type) {
    case "PrivateWithPassport":
      return "Private";
    case "PrivateWithSSN":
      return "Private";
    case "Company":
      return "Company";
    default:
      safeAssertNever(dto.type, { contexts: { args: { dto } } });
  }
}

function formatError(code: Ti18nKey, status: number): IRequestError {
  return {
    status,
    errors: [
      {
        message: {
          code,
        },
      },
    ],
  };
}

type CreateEntityFormProps = {
  onSave: (newEntity: NewEntity) => void;
  defaultValues: NewEntity;
  formId: string;
  error?: IRequestError | null;
  enabledEntityTypes?: EntityTypeOptions[];
  enableCountryChange?: boolean;
};

const CreateEntityForm: React.FunctionComponent<CreateEntityFormProps> = ({
  defaultValues,
  onSave,
  error,
  formId,
  enabledEntityTypes = ["Company", "PrivateWithSSN", "PrivateWithPassport"],
  enableCountryChange = true,
}) => {
  const i18n = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    clearErrors,
  } = useForm<EditEntityModel>({
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
      type: getTypeFromDto(defaultValues),
    },
  });
  const { user } = useSession();

  const currentCompany = useCurrentCompany();
  const entitiesQuery = useEntitiesQuery(currentCompany?.orgNumber || "");
  const entities = entitiesQuery.data || [];
  const entity = entities.find((e) => e.id === defaultValues.id);
  const formValues = watch();
  const entitySuggestionQuery = useEntitySuggestionQuery(
    {
      countryCode: formValues.countryCode,
      type: formValues.type === "Company" ? "Company" : "Private",
      refId: formValues.refId ?? "",
    },
    {
      onSuccess: (entitySuggestion) => {
        if (entitySuggestion) {
          setValue("contact", entitySuggestion.contact);
          clearErrors("contact");
          setValue("name", entitySuggestion.name);
          clearErrors("name");
        }
      },
    }
  );

  const isRefIdRequired = ["Company", "PrivateWithSSN"].includes(
    formValues.type
  );
  const isPassportRequired = formValues.type === "PrivateWithPassport";
  const isEmailRequired = (defaultValues.contact?.email?.length ?? 0) > 0;
  const isPhoneRequired = (defaultValues.contact?.phone?.length ?? 0) > 0;
  const isInEditMode = (defaultValues?.refId?.length ?? 0) > 0;
  const isForeignCompany =
    formValues.type === "Company" && formValues.countryCode !== "SE";
  const isNameInputDisabled =
    formValues.type !== "PrivateWithPassport" && !isForeignCompany;

  useEffect(() => {
    if (!isInEditMode) {
      setValue("refId", "");
      setValue("name", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.type]);

  useEffect(() => {
    if (!isInEditMode) {
      setValue("contact.address.countryCode", formValues.countryCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.countryCode]);

  useEffect(() => {
    const shouldSetNameAndAddressFromCachedResponse =
      formValues.type !== "PrivateWithPassport" &&
      !isInEditMode &&
      entitySuggestionQuery?.isFetched &&
      entitySuggestionQuery?.data?.refId === formValues.refId;

    if (shouldSetNameAndAddressFromCachedResponse) {
      const { name = "", contact } = entitySuggestionQuery?.data ?? {};
      const {
        line1 = "",
        line2 = "",
        city = "",
        postalCode = "",
        region = "",
      } = contact?.address ?? {};
      setValue("name", name);
      setValue("contact.address.line1", line1);
      setValue("contact.address.line2", line2);
      setValue("contact.address.city", city);
      setValue("contact.address.postalCode", postalCode);
      setValue("contact.address.region", region);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.refId]);

  const fetchRegisteredInfoError = useMemo<IRequestError | null>(() => {
    if (!entitySuggestionQuery.isError) {
      return null;
    }

    const isNotFound = entitySuggestionQuery.error?.status === 404;

    if (!isNotFound) {
      return entitySuggestionQuery.error;
    }

    const code: Ti18nKey =
      formValues.type === "Company"
        ? "entity.company.getRegisteredInfo.error.notFound"
        : "entity.private.getRegisteredInfo.error.notFound";

    return formatError(code, 404);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entitySuggestionQuery.error, entitySuggestionQuery.isError]);

  if (!currentCompany) {
    return null;
  }

  const showRegion = shouldShowRegion(
    formValues.contact.address?.countryCode || formValues.countryCode
  );
  const showAutoFill = canAutoFill({
    ...formValues,
    type: getTypeForDto(formValues),
  });
  const refIdError =
    error?.errors &&
    error?.errors
      .filter(({ field }) => field === "refId")
      .map(({ message: { code } }) => i18n.t(code))[0];

  let refIdLabel = "";
  let editOrgIdDisabled = true;
  let editCountryDisabled = true;
  if (formValues.type === "PrivateWithSSN") {
    refIdLabel = i18n.t("label.personId");
  } else if (formValues.type === "Company") {
    if (formValues.countryCode === "SE") {
      refIdLabel = i18n.t("label.organizationId");
    } else {
      refIdLabel = i18n.t("label.foreignOrganizationId");
      editOrgIdDisabled = false;
      editCountryDisabled = !enableCountryChange;
    }
  } else if (formValues.type === "PrivateWithPassport") {
    editCountryDisabled = !enableCountryChange;
  }

  return (
    <form
      className="tw-grid tw-gap-4 tw-pb-4 lg:tw-grid-cols-2 lg:tw-divide-x"
      id={formId}
      onSubmit={(event) => {
        event.stopPropagation();

        return handleSubmit((data) => {
          onSave({ ...data, type: getTypeForDto(data) });
        })(event);
      }}
    >
      <div>
        <div className="tw-space-y-3">
          <FormGroup>
            <FormLabel htmlFor="type">{i18n.t("label.type")}</FormLabel>
            <Controller
              control={control}
              name="type"
              render={({ field: { onChange, value }, fieldState }) => (
                <span data-testid="type">
                  <SelectEntityType
                    disabled={isInEditMode}
                    enabledOptions={enabledEntityTypes}
                    value={value}
                    onChange={onChange}
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </span>
              )}
              rules={{ required: i18n.t("error.validation.required") }}
            />
          </FormGroup>
          {isRefIdRequired && (
            <FormGroup>
              <FormLabel htmlFor="refId">{refIdLabel}</FormLabel>
              <Input
                id="refId"
                disabled={isInEditMode && editOrgIdDisabled}
                {...register("refId", {
                  validate: (value) => {
                    if (isRefIdRequired) {
                      const shouldBeSwedishPersonalNumber =
                        formValues.countryCode === "SE" &&
                        formValues.type === "PrivateWithSSN";
                      if (shouldBeSwedishPersonalNumber) {
                        const validationError = validateSwedishPersonalNumber(
                          value ?? ""
                        );
                        if (validationError) {
                          return i18n.t(validationError);
                        }
                      } else if (!value || value.trim().length < 2) {
                        return i18n.t("error.validation.required");
                      }
                    }
                    return true;
                  },
                })}
              />
              <FormError>{errors.refId?.message ?? refIdError}</FormError>
            </FormGroup>
          )}
          {isPassportRequired && (
            <FormGroup>
              <FormLabel
                htmlFor="passportNumber"
                secondaryLabel={i18n.t("label.passportNumber.secondary")}
              >
                {i18n.t("label.passportNumber")}
              </FormLabel>
              <Input
                id="passportNumber"
                {...register("passportNumber")}
                disabled={isInEditMode && user?.role !== "Administrator"}
              />
              <FormError>{errors.passportNumber?.message}</FormError>
            </FormGroup>
          )}
          <FormGroup>
            <FormLabel htmlFor="countryCode">
              {i18n.t("label.country")}
            </FormLabel>
            <Controller
              control={control}
              name="countryCode"
              render={({ field: { onChange, value, name }, fieldState }) => (
                <>
                  <SelectCountry
                    name={name}
                    value={value}
                    onChange={onChange}
                    disabled={isInEditMode && editCountryDisabled}
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              )}
              rules={{ required: i18n.t("error.validation.required") }}
            />
          </FormGroup>
          {user &&
            user?.role === "Administrator" &&
            isInEditMode &&
            showAutoFill && (
              <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-p-2">
                <Button
                  color="kvanta-elegant"
                  isLoading={entitySuggestionQuery.isFetching}
                  prefix={<MagicWand className="tw-mx-2" />}
                  variant="outline"
                  onClick={() => entitySuggestionQuery.refetch()}
                >
                  {i18n.t("entity.updateRegisteredInfo.action")}
                </Button>
                <FormHint>
                  {formValues.type === "Company"
                    ? i18n.t("entity.company.getRegisteredInfo.hint")
                    : i18n.t("entity.private.getRegisteredInfo.hint")}
                </FormHint>
                {fetchRegisteredInfoError && (
                  <div className="tw-p-2">
                    <FormErrorList error={fetchRegisteredInfoError} />
                  </div>
                )}
              </div>
            )}
          {showAutoFill && !isInEditMode && (
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-p-2">
              <Button
                color="primary"
                isLoading={entitySuggestionQuery.isFetching}
                variant="outline"
                onClick={() => entitySuggestionQuery.refetch()}
              >
                {i18n.t("entity.getRegisteredInfo.action")}
              </Button>
              <FormHint>
                {formValues.type === "Company"
                  ? i18n.t("entity.company.getRegisteredInfo.hint")
                  : i18n.t("entity.private.getRegisteredInfo.hint")}
              </FormHint>
              {fetchRegisteredInfoError && (
                <div className="tw-p-2">
                  <FormErrorList error={fetchRegisteredInfoError} />
                </div>
              )}
            </div>
          )}
          {entity && (
            <List
              className="max-md:tw-overflow-x-auto"
              header={
                <FormLabel>
                  {i18n.t(
                    formValues.type === "Company"
                      ? "label.representatives"
                      : "label.trustees"
                  )}
                </FormLabel>
              }
            >
              <FormHint>
                {i18n.t("entity.representatives.description", {
                  holder: entity.name,
                  company: currentCompany.name,
                })}
              </FormHint>
              {entity.children && entity.children?.length > 0 && (
                <table
                  className="tw-w-full tw-table-fixed"
                  data-testid="representatives-table"
                >
                  {entity.children.map((e) => {
                    const roleName = e.role && Roles[e.role];

                    return (
                      <tr key={e.id}>
                        <td className="tw-p-4">
                          <EntityItem
                            value={e}
                            hasFlag={false}
                            customDescription={
                              roleName ? i18n.t(roleName) : undefined
                            }
                          />
                        </td>
                        <td className="tw-p-4">
                          <div className="tw-flex tw-items-center tw-justify-center tw-gap-4">
                            {hasRequiredPermission(
                              "Editor",
                              currentCompany,
                              user
                            ) && (
                              <EditEntity
                                currentCompany={currentCompany}
                                value={e}
                              />
                            )}
                            {hasRequiredPermission(
                              "Editor",
                              currentCompany,
                              user
                            ) &&
                              !e.role && (
                                <RemoveRepresentative
                                  currentCompany={currentCompany}
                                  value={e}
                                  parent={entity}
                                  onSuccess={() => {
                                    entitiesQuery.refetch();
                                  }}
                                />
                              )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              )}
              {isInEditMode && (
                <div className="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-p-2">
                  <AddRepresentatives
                    currentCompany={currentCompany}
                    onSuccess={() => {
                      entitiesQuery.refetch();
                    }}
                    entity={entity}
                  />
                  <AttachRepresentatives
                    currentCompany={currentCompany}
                    onSuccess={() => {
                      entitiesQuery.refetch();
                    }}
                    entity={entity}
                    entities={entitiesQuery.data || []}
                    existingRepresentatives={entity.children || []}
                  />
                </div>
              )}
            </List>
          )}
        </div>
      </div>
      <div className="tw-space-y-4 lg:tw-px-4">
        <FormGroup>
          <FormLabel htmlFor="name">{i18n.t("label.name")}</FormLabel>
          <Input
            id="name"
            disabled={isNameInputDisabled}
            {...register("name", {
              validate: (value) => {
                if (!value.trim()) {
                  return i18n.t("error.validation.required");
                }

                return true;
              },
            })}
          />
          <FormError>{errors.name?.message}</FormError>
        </FormGroup>
        {formValues.birthDate && (
          <FormGroup>
            <FormLabel htmlFor="birthDate">
              {i18n.t("label.birthDate")}
            </FormLabel>
            <Input id="birthDate" {...register("birthDate")} disabled />
          </FormGroup>
        )}
        <FormSection title={i18n.t("label.address")}>
          <div className="tw-grid tw-gap-4 md:tw-grid-cols-2">
            <FormGroup>
              <FormLabel htmlFor="contact.address.line1">
                {i18n.t("entity.contact.address.line1")}
              </FormLabel>
              <Input
                id="contact.address.line1"
                {...register("contact.address.line1", {
                  validate: (value) => {
                    if (!value.trim()) {
                      return i18n.t("error.validation.required");
                    }

                    return true;
                  },
                })}
              />
              <FormError>{errors.contact?.address?.line1?.message}</FormError>
            </FormGroup>
            <FormGroup>
              <FormLabel isOptional htmlFor="contact.address.line2">
                {i18n.t("entity.contact.address.line2")}
              </FormLabel>
              <Input
                id="contact.address.line2"
                {...register("contact.address.line2")}
              />
              <FormError>{errors.contact?.address?.line2?.message}</FormError>
            </FormGroup>
          </div>
          <div className="tw-grid tw-gap-4 md:tw-grid-cols-2">
            <FormGroup>
              <FormLabel htmlFor="contact.address.city">
                {i18n.t("entity.contact.address.city")}
              </FormLabel>
              <Input
                id="contact.address.city"
                {...register("contact.address.city", {
                  validate: (value) => {
                    if (!value.trim()) {
                      return i18n.t("error.validation.required");
                    }

                    return true;
                  },
                })}
              />
              <FormError>{errors.contact?.address?.city?.message}</FormError>
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="contact.address.postalCode">
                {i18n.t("entity.contact.address.postalCode")}
              </FormLabel>
              <Input
                id="contact.address.postalCode"
                {...register("contact.address.postalCode", {
                  validate: (value) => {
                    if (!value.trim()) {
                      return i18n.t("error.validation.required");
                    }

                    const postalCodeRegex: Record<string, RegExp> = {
                      SE: /^\d{3}\s?\d{2}$/,
                    };

                    const regex =
                      postalCodeRegex[
                        formValues.contact.address?.countryCode ||
                          formValues.countryCode
                      ];
                    if (regex === undefined) {
                      return true;
                    }

                    return (
                      regex.test(value) || i18n.t("error.validation.format")
                    );
                  },
                })}
              />
              <FormError>
                {errors.contact?.address?.postalCode?.message}
              </FormError>
            </FormGroup>
          </div>
          {showRegion ? (
            <FormGroup>
              <FormLabel htmlFor="contact.address.region" isOptional>
                {i18n.t("entity.contact.address.region")}
              </FormLabel>
              <Input
                id="contact.address.region"
                {...register("contact.address.region")}
              />
              <FormError>{errors.contact?.address?.region?.message}</FormError>
            </FormGroup>
          ) : null}
          <FormGroup>
            <FormLabel htmlFor="contact.address.countryCode">
              {i18n.t("label.country")}
            </FormLabel>
            <Controller
              control={control}
              name="contact.address.countryCode"
              render={({ field: { onChange, value, name }, fieldState }) => (
                <>
                  <SelectCountry
                    name={name}
                    value={value}
                    onChange={onChange}
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              )}
              rules={{ required: i18n.t("error.validation.required") }}
            />
          </FormGroup>
        </FormSection>
        <FormSection title={i18n.t("label.contact")}>
          <FormGroup>
            <FormLabel htmlFor="contact.email" isOptional={!isEmailRequired}>
              {i18n.t("label.email")}
            </FormLabel>
            <Input
              id="contact.email"
              {...register("contact.email", {
                required: isEmailRequired,
                pattern: {
                  value: emailRegex,
                  message: i18n.t("error.validation.format"),
                },
              })}
              type="email"
            />
            <FormError>{errors.contact?.email?.message}</FormError>
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="contact.phone" isOptional={!isPhoneRequired}>
              {i18n.t("label.phone")}
            </FormLabel>
            <Controller
              control={control}
              name="contact.phone"
              render={({ field: { onChange, value, name }, fieldState }) => (
                <>
                  <PhoneInput
                    id="contact.phone"
                    name={name}
                    value={value ?? undefined}
                    onChange={onChange}
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              )}
              rules={{
                required: isPhoneRequired,

                validate: (value) => {
                  if (!value) {
                    return true;
                  }

                  return isPossiblePhoneNumber(value)
                    ? true
                    : i18n.t("error.validation.format.phone");
                },
              }}
            />
          </FormGroup>
        </FormSection>
      </div>
      {error && (
        <div className="lg:tw-col-span-2">
          <FormErrorList error={error} />
        </div>
      )}
    </form>
  );
};

export { CreateEntityForm };
export type { EntityTypeOptions };
