import { ResponsivePie } from "@nivo/pie";
import { ArrowsOutSimple } from "@phosphor-icons/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Description } from "../../components/design-system/Description";
import { ShareBlockDialog } from "../../components/ShareBlocks/Dialog";
import { ShareType } from "../../types/models/shares";
import { formatNumber } from "../../utils/format";

type ShareClassRowProps = {
  shareType: ShareType;
  color: string;
  total: number;
};

const ShareClassRow = ({ shareType, color, total }: ShareClassRowProps) => {
  const [open, setOpen] = useState(false);

  return (
    <button type="button" onClick={() => setOpen(true)}>
      <div className="tw-flex tw-h-full tw-justify-between tw-rounded tw-border tw-p-2 tw-text-left hover:tw-bg-neutral-50">
        <div className="tw-flex tw-gap-4">
          <div
            style={{ backgroundColor: color }}
            className="tw-h-full tw-w-2 tw-rounded-sm"
          />
          <Description
            title={shareType.name}
            description={formatNumber(total)}
            descriptionWeight="medium"
            theme="grayBlack"
          />
        </div>
        <ArrowsOutSimple className="tw-h-full" />
      </div>
      <ShareBlockDialog
        isOpen={open}
        onClose={() => setOpen(false)}
        title={shareType.name}
        shares={total}
        votes={shareType.voteValue}
        conditions={shareType.condition}
        size="sm"
      />
    </button>
  );
};

type ShareClassesProps = {
  shareClasses: ShareType[];
  shareTotals: Record<string, number>;
  totalShares: number;
};

const colorPalette = ["#563F6F", "#A084BE", "#D7C5EB"];

const ShareClasses = ({
  shareClasses,
  shareTotals,
  totalShares,
}: ShareClassesProps) => {
  const i18n = useTranslation();
  const usedShareClasses = shareClasses.filter(
    (c) => shareTotals[c.name] !== undefined
  );
  const data = usedShareClasses.map((c) => ({
    id: c.name,
    label: c.name,
    value: shareTotals[c.name],
  }));

  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-whitespace-nowrap tw-rounded-lg tw-border tw-border-neutral-200 tw-p-4">
      <div className="tw-flex tw-justify-center">
        <div style={{ height: "200px", width: "200px", position: "relative" }}>
          <ResponsivePie
            data={data}
            margin={{ left: 10, right: 10, top: 10, bottom: 10 }}
            innerRadius={0.75}
            cornerRadius={0}
            colors={colorPalette}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            tooltip={({ datum }) => (
              <div className="tw-flex tw-items-center tw-gap-2 tw-rounded tw-border tw-bg-white tw-p-2">
                <div
                  className="tw-h-4 tw-w-4 tw-rounded-sm"
                  style={{ backgroundColor: datum.color }}
                />
                <span className="tw-text-sm">{datum.label}:</span>
                <span className="tw-text-sm tw-font-medium">
                  {formatNumber(datum.value)}
                </span>
              </div>
            )}
          />
          {/* Add a centered label */}
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
            }}
          >
            <p className="tw-text-sm tw-text-secondary">
              {i18n.t("externalView.shareTypes.total")}
            </p>
            <p className="tw-text-sm tw-font-medium tw-text-primary">
              {formatNumber(totalShares)}
            </p>
          </div>
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-2">
        {usedShareClasses.map((c, i) => (
          <ShareClassRow
            key={c.name}
            shareType={c}
            color={colorPalette[i % colorPalette.length]!}
            total={shareTotals[c.name]!}
          />
        ))}
      </div>
    </div>
  );
};

export { ShareClasses };
