import type {
  CompanyInformationResponse,
  RegisterCompanyRequest,
} from "@capchapdev/admin-api";
import type {
  UseMutationOptions,
  UseQueryOptions,
} from "@tanstack/react-query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useBlockchainClient } from "../../context/blockchain";
import type {
  CompanyCases,
  CompanyDetails,
  CompanyShareCapitalEvents,
  NewCompany,
  NewTestCompany,
} from "../../types/models/administration";
import {
  CompanyCasesSchema,
  CompanyDetailsSchema,
  CompanyShareCapitalEventsSchema,
} from "../../types/models/administration";
import type {
  BaseCompanyEntity,
  CompanyLegalStatus,
} from "../../types/models/company";
import type { NewBoardMember } from "../../types/models/users";
import * as monitoring from "../../utils/monitoring";
import type { IRequestError } from "..";
import { BlockchainClient } from "../blockchain/client";
import useClient, { NoContentSchema, URL } from "./client";
import { RawTxResponse, RawTxResponseSchema } from "./users";

const registerSwedishCompany = async (body: NewCompany) => {
  const client = useClient({ hasAuth: true });

  const response = await client<undefined>(`${URL.ADMIN}/Admin/Register`, {
    body,
  });

  const result = NoContentSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response;
  }

  return result.data;
};

const registerSwedishCompanyWithContactDetails = async (
  body: RegisterCompanyRequest
) => {
  const client = useClient({ hasAuth: true });

  const response = await client<undefined>(`${URL.ADMIN}/Company/Register`, {
    body,
  });

  const result = NoContentSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(
      TypeError("Error parsing response using NoContentSchema"),
      { contexts: { response, result } }
    );

    return response;
  }

  return result.data;
};

const registerTestCompany = async (body: NewTestCompany) => {
  const client = useClient({ hasAuth: true });

  const response = await client<undefined>(`${URL.ADMIN}/FakeData/Company`, {
    body,
  });

  const result = NoContentSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response;
  }

  return result.data;
};

const addBoardMember = async (orgNumber: string, body: NewBoardMember) => {
  const client = useClient({ hasAuth: true });

  const response = await client<undefined>(
    `${URL.ADMIN}/FakeData/Company/${orgNumber}/BoardMember`,
    { method: "POST", body }
  );

  const result = NoContentSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response;
  }

  return result.data;
};

const deleteBoardMember = async (orgNumber: string, id: string) => {
  const client = useClient({ hasAuth: true });

  const response = await client<undefined>(
    `${URL.ADMIN}/Company/${orgNumber}/BoardMember/${id}`,
    { method: "DELETE" }
  );

  const result = NoContentSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response;
  }

  return result.data;
};

const getCompanyDetails = async (
  orgNumber: string
): Promise<CompanyDetails | null> => {
  const client = useClient({ hasAuth: true });

  const response = await client<CompanyInformationResponse>(
    `${URL.ADMIN}/Company/${orgNumber}/Info`,
    {},
    [404]
  );

  const result = CompanyDetailsSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response as CompanyDetails;
  }

  return result.data || null;
};

const updateCompanyStatusAndAccess = async (
  requestContent: UpdateCompanyStatusAndAccessRequestType
) => {
  const client = useClient({ hasAuth: true });

  const response = await client<undefined>(
    `${URL.ADMIN}/FakeData/UpdateCompanyStatusAndAccess`,
    {
      method: "POST",
      body: requestContent,
    }
  );

  const result = NoContentSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });
    return response;
  }

  return result.data;
};

const clearCompanyDataCache = async (orgNumber: string) => {
  const client = useClient({ hasAuth: true });

  const response = await client<undefined>(
    `${URL.ADMIN}/Admin/CompanyData/${orgNumber}`,
    {
      method: "DELETE",
    }
  );

  const result = NoContentSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });
    return false;
  }

  return result.success;
};

const clearCompanyInvolvementDataCache = async (ssn: string) => {
  const client = useClient({ hasAuth: true });

  const response = await client<undefined>(
    `${URL.ADMIN}/Admin/CompanyInvolvementData/${ssn}`,
    {
      method: "DELETE",
    }
  );

  const result = NoContentSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });
    return false;
  }

  return result.success;
};

const refreshCompanyDetails = async (orgNumber: string) => {
  const client = useClient({ hasAuth: true });

  const response = await client<undefined>(
    `${URL.ADMIN}/Admin/RefreshCompanyDetails/${orgNumber}`,
    {
      method: "POST",
    }
  );

  const result = NoContentSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });
    return false;
  }

  return result.success;
};

const useClearCompanyDataCacheMutation = (
  options?: UseMutationOptions<boolean, IRequestError, string>
) =>
  useMutation<boolean, IRequestError, string>(
    (orgNumber) => clearCompanyDataCache(orgNumber),
    options
  );

const useRefreshCompanyDetailsMutation = (
  options?: UseMutationOptions<boolean, IRequestError, string>
) => {
  const queryClient = useQueryClient();
  return useMutation<boolean, IRequestError, string>(
    (orgNumber) => refreshCompanyDetails(orgNumber),
    {
      ...options,
      onSuccess: (data, orgNumber, context) => {
        queryClient.invalidateQueries({
          queryKey: ["companyInvolvements"],
        });
        queryClient.invalidateQueries({
          queryKey: ["companyDetails", orgNumber],
        });
        options?.onSuccess?.(data, orgNumber, context);
      },
    }
  );
};

const useClearCompanyInvolvementDataCacheMutation = (
  options?: UseMutationOptions<boolean, IRequestError, string>
) =>
  useMutation<boolean, IRequestError, string>(
    (ssn) => clearCompanyInvolvementDataCache(ssn),
    options
  );

const useRegisterSwedishCompanyMutation = (
  options?: UseMutationOptions<unknown, IRequestError, NewCompany>
) =>
  useMutation<unknown, IRequestError, NewCompany>(
    (data) => registerSwedishCompany(data),
    options
  );

const useRegisterSwedishCompanyWithContactDetailsMutation = (
  options?: UseMutationOptions<unknown, IRequestError, RegisterCompanyRequest>
) =>
  useMutation<unknown, IRequestError, RegisterCompanyRequest>(
    (data) => registerSwedishCompanyWithContactDetails(data),
    options
  );

const useRegisterTestCompanyMutation = (
  options?: UseMutationOptions<unknown, IRequestError, NewTestCompany>
) =>
  useMutation<unknown, IRequestError, NewTestCompany>(
    (data) => registerTestCompany(data),
    options
  );

const useAddBoardMemberMutation = (
  orgNumber: string,
  options?: UseMutationOptions<unknown, IRequestError, NewBoardMember>
) => {
  const queryClient = useQueryClient();
  return useMutation<unknown, IRequestError, NewBoardMember>(
    (data) => addBoardMember(orgNumber, data),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: ["users", orgNumber],
        });
        queryClient.invalidateQueries({
          queryKey: ["entities", orgNumber],
        });
        options?.onSuccess?.(data, variables, context);
      },
    }
  );
};

const useDeleteBoardMemberMutation = (
  orgNumber: string,
  id: string,
  options?: UseMutationOptions<unknown, IRequestError>
) =>
  useMutation<unknown, IRequestError>(
    () => deleteBoardMember(orgNumber, id),
    options
  );

const useCompanyDetailsQuery = (
  orgNumber?: string,
  options?: UseQueryOptions<CompanyDetails | null | undefined, IRequestError>
) =>
  useQuery<CompanyDetails | null | undefined, IRequestError>(
    ["companyDetails", orgNumber],
    () => {
      if (orgNumber) {
        return getCompanyDetails(orgNumber);
      }
      return undefined;
    },
    {
      enabled: options?.enabled !== undefined ? options.enabled : !!orgNumber,
      ...options,
    }
  );

const getCompanyShareCapitalEvents = async (
  orgNumber: string
): Promise<CompanyShareCapitalEvents | null> => {
  const client = useClient({ hasAuth: true });

  const response = await client<CompanyShareCapitalEvents>(
    `${URL.ADMIN}/Company/${orgNumber}/ShareCapitalChanges`,
    {},
    [404]
  );

  const result = CompanyShareCapitalEventsSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response: response || undefined, result },
    });

    return response;
  }

  return result.data || null;
};

const useCompanyShareCapitalEventsQuery = (
  orgNumber?: string,
  options?: UseQueryOptions<CompanyShareCapitalEvents | null, IRequestError>
) =>
  useQuery<CompanyShareCapitalEvents | null, IRequestError>(
    ["companyShareCapitalEvents", orgNumber],
    () => {
      if (orgNumber) {
        return getCompanyShareCapitalEvents(orgNumber);
      }
      return undefined;
    },
    {
      enabled: options?.enabled !== undefined ? options.enabled : !!orgNumber,
      ...options,
    }
  );

const getCompanyCases = async (
  orgNumber: string
): Promise<CompanyCases | null> => {
  const client = useClient({ hasAuth: true });

  const response = await client<CompanyCases>(
    `${URL.ADMIN}/Company/${orgNumber}/Cases`,
    {},
    [404]
  );

  const result = CompanyCasesSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response: response || undefined, result },
    });

    return response;
  }

  return result.data || null;
};

const useCompanyCasesQuery = (
  orgNumber?: string,
  options?: UseQueryOptions<CompanyCases | null, IRequestError>
) =>
  useQuery<CompanyCases | null, IRequestError>(
    ["companyCases", orgNumber],
    () => {
      if (orgNumber) {
        return getCompanyCases(orgNumber);
      }
      return undefined;
    },
    {
      enabled: options?.enabled !== undefined ? options.enabled : !!orgNumber,
      ...options,
    }
  );

type UpdateCompanyStatusAndAccessRequestType = {
  orgNumber: string;
  status: CompanyLegalStatus;
  liquidators: BaseCompanyEntity[];
};

const useUpdateCompanyStatusAndAccessMutation = (
  options?: UseMutationOptions<
    unknown,
    IRequestError,
    UpdateCompanyStatusAndAccessRequestType
  >
) =>
  useMutation<unknown, IRequestError, UpdateCompanyStatusAndAccessRequestType>(
    ({ orgNumber, status, liquidators }) =>
      updateCompanyStatusAndAccess({
        orgNumber,
        status,
        liquidators,
      }),
    options
  );

export type UpdateCompanyPolicyRequestType = {
  type: "BoardPercentage" | "SpecificUsers";
  percentage?: number;
  users: string[];
};

const updateCompanyPolicy = async (
  blockchainClient: BlockchainClient,
  orgNumber: string,
  requestContent: UpdateCompanyPolicyRequestType
) => {
  const client = useClient({ hasAuth: true });

  const response = await client<RawTxResponse>(
    `${URL.ADMIN}/Admin/OverridePolicy/${orgNumber}`,
    {
      method: "POST",
      body: requestContent,
    }
  );

  const result = RawTxResponseSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return blockchainClient.sendTransaction(response.rawTx);
  }

  return blockchainClient.sendTransaction(result.data.rawTx);
};

const useUpdateCompanyPolicyMutation = (
  orgNumber: string,
  options?: UseMutationOptions<
    unknown,
    IRequestError,
    UpdateCompanyPolicyRequestType
  >
) => {
  const blockchainClient = useBlockchainClient();
  return useMutation<unknown, IRequestError, UpdateCompanyPolicyRequestType>(
    (data) => updateCompanyPolicy(blockchainClient, orgNumber, data),
    options
  );
};

export {
  useAddBoardMemberMutation,
  useClearCompanyDataCacheMutation,
  useClearCompanyInvolvementDataCacheMutation,
  useCompanyCasesQuery,
  useCompanyDetailsQuery,
  useCompanyShareCapitalEventsQuery,
  useDeleteBoardMemberMutation,
  useRefreshCompanyDetailsMutation,
  useRegisterSwedishCompanyMutation,
  useRegisterSwedishCompanyWithContactDetailsMutation,
  useRegisterTestCompanyMutation,
  useUpdateCompanyPolicyMutation,
  useUpdateCompanyStatusAndAccessMutation,
};
