import { ShareFat } from "@phosphor-icons/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { LedgerVersionDetails } from "../../pages/CompanyShares/useLedgerVersions";
import { ViewDialog } from "../../pages/ViewSharing/ViewDialog";
import { Button } from "../design-system/Button";
import { notify } from "../design-system/Notifications";

const ShareView = ({
  orgNumber,
  selectedVersion,
}: {
  orgNumber: string;
  selectedVersion?: LedgerVersionDetails;
}) => {
  const i18n = useTranslation();
  const [showShareDialog, setShowShareDialog] = useState(false);

  return (
    <>
      <Button
        onClick={() => setShowShareDialog(true)}
        className="tw-rounded-md max-md:tw-p-3"
      >
        <span className="tw-hidden md:tw-mr-2 md:tw-block">
          {i18n.t("shareholders.share")}
        </span>
        <ShareFat />
      </Button>
      {showShareDialog && (
        <ViewDialog
          defaultValues={
            selectedVersion
              ? { shareLedgerVersion: selectedVersion.formatedValue }
              : undefined
          }
          onClose={() => {
            setShowShareDialog(false);
          }}
          onSuccess={() => {
            notify(
              <div className="tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-4">
                <p className="tw-text-sm tw-font-medium">
                  {i18n.t("shareholders.share.success.title")}
                </p>
                <Link to={`/companies/${orgNumber}/views`}>
                  <Button size="md" className="tw-font-medium">
                    {i18n.t("shareholders.share.success.link")}
                  </Button>
                </Link>
              </div>,
              { type: "success" }
            );
            setShowShareDialog(false);
          }}
          orgNumber={orgNumber}
        />
      )}
    </>
  );
};

export { ShareView };
