import type { UseTranslationResponse } from "react-i18next";

import { getLocale } from "../i18n/locale";
import { ShareHolderWithDilution } from "../pages/CompanyShares/ShareHolders.utils";
import { EntitiesMap } from "../types/models/entities";
import {
  Shareblock,
  ShareBlockHistory,
  ShareTypeClause,
  ShareTypesMap,
} from "../types/models/shares";
import { getCountryName } from "./country";
import { getFormattedDate, isoStringToDate } from "./date";
import { formatAddress, formatRefId } from "./format";
import { SpreadsheetData } from "./xlsx";

const generateShareholderData = (
  shareholders: ShareHolderWithDilution[],
  displayDiluted: boolean,
  ledger: {
    sharesByType: Record<string, number>;
    shares: number;
    votes: number;
    dilutedShares: number;
    dilutedVotes: number;
  },
  i18n: UseTranslationResponse<"translation">
): SpreadsheetData => {
  const sharesLabel = i18n.t("label.shares").toLowerCase();
  return shareholders.map((s) => ({
    [i18n.t("label.shareholder")]: s.entity.name,
    [`${i18n.t("label.representatives")} / ${i18n.t("label.trustees")}`]:
      s.entity.children?.map((c) => c.name).join(",") || "",
    [i18n.t("label.type")]: i18n.t(
      s.entity.type === "Private" ? "label.private" : "label.company"
    ),
    [i18n.t("label.shareholderSince")]:
      isoStringToDate(s.holder.since)?.toLocaleDateString(getLocale()) || "",
    [i18n.t("label.id")]: formatRefId({
      refId: s.entity.refId,
      countryCode: s.entity.countryCode,
      birthDate: s.entity.birthDate,
      type: s.entity.type,
    }),
    [i18n.t("label.country")]: getCountryName(s.entity.countryCode) || "",
    [i18n.t("label.address")]: s.entity.contact.address
      ? formatAddress(s.entity.contact.address)
      : "",
    ...Object.fromEntries(
      Object.keys(ledger.sharesByType).map((key) => [
        `${key} ${sharesLabel}`,
        s.shareTypes[key] || 0,
      ])
    ),
    [i18n.t("label.shares")]: s.totalShares || 0,
    ...(displayDiluted &&
      ledger.dilutedShares && {
        [i18n.t("label.diluted.shares")]: ledger.dilutedShares,
      }),
    [i18n.t("label.votes")]: s.totalVotes || 0,
    ...(displayDiluted &&
      ledger.dilutedVotes && {
        [i18n.t("label.diluted.votes")]: ledger.dilutedVotes,
      }),
  }));
};

const generateShareledgerData = (
  shareblocks: Shareblock[],
  entitiesMap: EntitiesMap,
  shareTypesMap: ShareTypesMap,
  shareBlockHistory: ShareBlockHistory[],
  conditionOptions: {
    value: ShareTypeClause;
    label: string;
  }[],
  i18n: UseTranslationResponse<"translation">
): SpreadsheetData => {
  return shareblocks
    .filter((x) => !x.cancelled)
    .map((block) => {
      if (!block) {
        return undefined;
      }
      const holder = entitiesMap[block.holder.id];
      if (!holder) {
        return undefined;
      }
      const shareType = shareTypesMap[block.type];
      if (!shareType) {
        return undefined;
      }

      const creditor = entitiesMap[block.creditor?.id || ""];
      const matchingHistory = shareBlockHistory.find(
        (history) =>
          (history.holder.id === block.holder.id ||
            history.holder.refId === entitiesMap[block.holder.id]?.refId) &&
          history.start === block.start &&
          history.end === block.end
      );

      const { start, end, type } = block;
      return {
        [i18n.t("label.shareBlock")]: `${start} - ${end}`,
        [i18n.t("label.shareType")]: type,
        [i18n.t("label.shareholder")]: holder.name,
        [i18n.t("label.id")]: formatRefId({
          refId: holder.refId,
          countryCode: holder.countryCode,
          birthDate: holder.birthDate,
          type: holder.type,
        }),
        [i18n.t("label.ownershipInfo")]: holder.contact.address
          ? formatAddress(holder.contact.address)
          : "",
        [i18n.t("label.entry")]: matchingHistory
          ? getFormattedDate(matchingHistory.date) || ""
          : "",
        [i18n.t("shares.certificates")]: block.hasCertificateSince
          ? i18n.t("shares.certificates.issued", {
              date: getFormattedDate(block.hasCertificateSince),
            })
          : "",
        [i18n.t("label.creditor")]: creditor ? creditor.name : "",
        [i18n.t("shares.restrictiveConditions")]: Object.values(
          shareType.condition
        ).some(Boolean)
          ? conditionOptions
              .filter((option) => !!shareType.condition[option.value])
              .map((x) => x.label)
              .join(", ")
          : "",
        [i18n.t("shares.history")]:
          matchingHistory && matchingHistory.previousHolders
            ? matchingHistory.previousHolders
                .map(
                  (x) =>
                    `${entitiesMap[x.holder.id]?.name} (${getFormattedDate(
                      x.date
                    )})`
                )
                .join(", ")
            : "",
        [i18n.t("label.trustees")]:
          holder?.type === "Private" && holder?.children
            ? holder.children.map((x) => entitiesMap[x.id]?.name).join(", ")
            : "",
        [i18n.t("label.shares")]: end - start + 1,
        [i18n.t("label.votes")]: shareType.voteValue * (end - start + 1),
      };
    })
    .filter((x) => x !== undefined);
};

export { generateShareholderData, generateShareledgerData };
