import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { IconDescription } from "../../IconDescription";
import { ArrowRightIcon, CrossIcon } from "../icons";

type Notification = {
  link: string;
  message: string;
  linkText: string;
};

const Banner = ({
  link,
  message,
  linkText,
  icon,
}: {
  link: string;
  message: string;
  linkText: string;
  icon?: JSX.Element;
}) => {
  const i18n = useTranslation();
  const [isVisible, setIsVisible] = useState(true);
  const handleClose = () => setIsVisible(false);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="tw-mx-auto tw-max-w-7xl">
      <div className="tw-mx-4 tw-mt-4 tw-rounded tw-bg-kvanta-purple tw-py-4 print:tw-hidden">
        <div className="tw-mx-auto tw-flex tw-min-h-[32px] tw-items-center tw-gap-x-3 tw-px-4 tw-text-sm tw-text-kvanta-purple-dark">
          <div
            className="tw-flex tw-w-full tw-items-center tw-justify-between"
            data-testid="pending-events-count"
          >
            <div className="tw-flex tw-items-center tw-gap-2">
              {icon}
              <Link to={link} className="tw-text-kvanta-purple-dark">
                {message}
              </Link>
            </div>
            <div className="tw-flex tw-items-center">
              <button onClick={handleClose} type="button">
                <IconDescription
                  title={i18n.t("pendingEventsNotification.close")}
                  icon={<CrossIcon />}
                  iconFirst={false}
                  theme="purple"
                  className="tw-cursor-pointer tw-gap-2 tw-px-4 max-md:tw-hidden"
                />
              </button>
              <Link to={link} className="tw-text-kvanta-purple-dark">
                <IconDescription
                  title={linkText}
                  icon={<ArrowRightIcon />}
                  iconFirst={false}
                  theme="purple"
                  className="tw-gap-2 tw-px-4"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Banner };
export type { Notification };
