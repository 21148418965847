import { ShareType } from "@capchapdev/rell-api";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type { useEntitiesQuery } from "../../../api/rest/entities";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type { NewShareBlock } from "../../../types/models/draft";
import { Button } from "../../design-system/Button";
import { Dialog } from "../../design-system/Dialog";
import { FormError, FormGroup, FormLabel } from "../../design-system/FormGroup";
import { Input } from "../../design-system/Input";
import { Select } from "../../design-system/Select";
import { SelectEntity } from "../../design-system/SelectEntity";
import { AddEntity } from "../../Entities/AddEntity";

const formId = "edit-shareblock-form";

type EditShareBlockDialogProps = {
  title: string;
  onClose: () => void;
  onSuccess: (value: NewShareBlock) => void;
  defaultValues?: NewShareBlock;
  entitiesQuery: ReturnType<typeof useEntitiesQuery>;
  currentCompany: CompanyInvolvement | CompanyInformation;
  shareTypes: ShareType[];
  disableNewEntity?: boolean;
};

const EditShareBlockDialog = ({
  title,
  onClose,
  onSuccess,
  defaultValues,
  entitiesQuery,
  currentCompany,
  shareTypes,
  disableNewEntity,
}: EditShareBlockDialogProps) => {
  const i18n = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<NewShareBlock>({ mode: "onChange", defaultValues });
  const entities = entitiesQuery.data || [];

  return (
    <Dialog
      isOpen
      title={title}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose}>{i18n.t("label.cancel")}</Button>
          <Button type="submit" variant="solid" color="primary" form={formId}>
            {i18n.t("label.save")}
          </Button>
        </>
      }
    >
      <form
        className="tw-space-y-4"
        onSubmit={(event) => {
          event.stopPropagation();

          return handleSubmit((data) => onSuccess(data))(event);
        }}
        id={formId}
      >
        <FormGroup>
          <Controller
            control={control}
            render={({ field: { onChange, value }, fieldState }) => {
              const canAddNew = !disableNewEntity;
              const label = canAddNew ? (
                <div className="tw-flex tw-items-end tw-justify-between">
                  <FormLabel htmlFor="shareholder">
                    {i18n.t("label.shareholder")}
                  </FormLabel>
                  <AddEntity
                    currentCompany={currentCompany}
                    onSuccess={(newEntity) => {
                      entitiesQuery.refetch();
                      onChange(newEntity.id);
                    }}
                  />
                </div>
              ) : (
                <FormLabel htmlFor="shareholder">
                  {i18n.t("label.shareholder")}
                </FormLabel>
              );

              return (
                <>
                  {label}
                  <SelectEntity
                    id="shareholderSelect"
                    options={entities}
                    value={value}
                    onChange={onChange}
                    menuPosition="fixed"
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              );
            }}
            name="holderId"
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="type">{i18n.t("label.shareClass")}</FormLabel>
          <Controller
            control={control}
            render={({ field: { onChange, value }, fieldState }) => (
              <>
                <Select
                  name="shareClassSelect"
                  options={shareTypes}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.name}
                  value={shareTypes.find(({ name }) => name === value)}
                  onChange={(newValue) => onChange(newValue?.name)}
                  menuPosition="fixed"
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            name="type"
          />
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="numberOfShares">
            {i18n.t("label.numberOfShares")}
          </FormLabel>
          <Input
            id="numberOfShares"
            {...register("numberOfShares", {
              required: i18n.t("error.validation.required"),
              valueAsNumber: true,
              min: {
                value: 1,
                message: i18n.t("error.validation.range.min", { min: 1 }),
              },
            })}
            type="number"
            step={1}
          />
          <FormError>{errors.numberOfShares?.message}</FormError>
        </FormGroup>
      </form>
    </Dialog>
  );
};

export { EditShareBlockDialog };
