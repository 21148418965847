import "./toast.css";

import { toast } from "react-toastify";

import type { AlertType } from "../../Alert";
import { CheckmarkIcon, ErrorIcon, InfoIcon, WarningIcon } from "../../icons";

const notify = Object.assign(
  (
    message: React.ReactNode,
    { type, ...options }: { type: AlertType } = { type: "neutral" }
  ) => {
    const Icon = {
      success: CheckmarkIcon,
      error: ErrorIcon,
      warning: WarningIcon,
      info: InfoIcon,
      neutral: InfoIcon,
    }[type];

    return toast(
      () => (
        <div className="tw-flex tw-items-center tw-gap-3">
          <Icon className="tw-text-neutral-800" />
          {message}
        </div>
      ),
      {
        className:
          "!tw-min-h-0 !tw-rounded !tw-border !tw-px-3 !tw-py-2 !tw-text-body !tw-shadow-xl",
        closeButton: false,
        position: toast.POSITION.BOTTOM_CENTER,
        ...options,
      }
    );
  },
  toast
);

export { notify };
