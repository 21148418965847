import { Trans, useTranslation } from "react-i18next";

import { useDeleteEventAttachmentMutation } from "../../api/rest/attachments";
import { Alert } from "../design-system/Alert";
import { Button } from "../design-system/Button";
import { Dialog } from "../design-system/Dialog";

type DeleteAttachmentProps = {
  orgNumber: string;
  eventId: string;
  documentId: string;
  onClose: () => void;
};

const DeleteAttachment = ({
  orgNumber,
  eventId,
  documentId,
  onClose,
}: DeleteAttachmentProps) => {
  const i18n = useTranslation();
  const deleteMutation = useDeleteEventAttachmentMutation(orgNumber, eventId, {
    onSuccess: onClose,
  });

  return (
    <Dialog
      isOpen
      title={i18n.t("event.attachments.delete.title")}
      onClose={onClose}
      isLoading={deleteMutation.isLoading}
      actions={
        <>
          <Button disabled={deleteMutation.isLoading} onClick={onClose}>
            {i18n.t("label.cancel")}
          </Button>
          <Button
            type="submit"
            variant="solid"
            color="primary"
            isLoading={deleteMutation.isLoading}
            onClick={() => {
              deleteMutation.mutate(documentId);
            }}
          >
            {i18n.t("event.attachments.delete.button")}
          </Button>
        </>
      }
    >
      <p>
        <Trans
          values={{
            filename: documentId,
          }}
          i18nKey="event.attachments.delete.description"
        />
      </p>
      {deleteMutation.error && (
        <Alert type="error" className="tw-mt-2">
          {i18n.t("event.attachments.delete.error")}
        </Alert>
      )}
    </Dialog>
  );
};

export { DeleteAttachment };
